import React, { FC } from 'react'

import { MobileHamburgerSC } from './MobileHamburger.styles';

type MobileHamburgerProps = {
    onClick: () => void;
    isOpen: boolean;
    isScroll:boolean;
}

export const MobileHamburger: FC<MobileHamburgerProps> = ({ onClick, isOpen, isScroll }) => (
    <MobileHamburgerSC.Root onClick={onClick} isOpen={isOpen} isScroll={isScroll}>
        <MobileHamburgerSC.Line />
        <MobileHamburgerSC.Line />
    </MobileHamburgerSC.Root>
)
