import styled, { FlattenSimpleInterpolation } from 'styled-components';

import { Font, MediaQueries } from 'shared/config';

const Root = styled.div<{ styles?:FlattenSimpleInterpolation }>`
    padding: 16px 45px;
    background: linear-gradient(45deg, #F1E6FF 0%, #F3F6FC 100%);
    border-radius: 16px;
    display: inline-block;
    text-align: center;
    font-family: ${Font.bold};

    @media (max-width: ${MediaQueries.tablet}) {
      font-size: 14px;
      padding: 12px 25px;
    }

    ${props => props.styles};
`;

export const TextLabelSC = {
  Root
};
