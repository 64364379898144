import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TextLabel, Typography } from 'shared/components/ui';

import { featuresList } from './Features.mocks';

import { LandingPageSC } from 'shared/index.styles';
import { FeaturesSC } from './Features.styles';

export const FeaturesSection: FC = () => {
  const { t } = useTranslation();

  return (
    <FeaturesSC.Root id="features">
      <LandingPageSC.SectionSeparator  />
      <Typography.H2 styles={FeaturesSC.TitleCss}>
        {t('lp_feature_title')}
      </Typography.H2>
      <FeaturesSC.List>
        {featuresList.map((item, index) => (
          <FeaturesSC.Item key={`LPFeatures-item-${index}`}>
            <FeaturesSC.ImageWrapper>
              {React.createElement(item.illustration)}
            </FeaturesSC.ImageWrapper>
            <FeaturesSC.Body>
              <TextLabel>
                {t(item.label)}
              </TextLabel>
              <Typography.H2 styles={FeaturesSC.ItemTitleCss}>
                {t(item.title)}
              </Typography.H2>
              <Typography.Text>
                {t(item.text)}
              </Typography.Text>
            </FeaturesSC.Body>
          </FeaturesSC.Item>
        ))}
      </FeaturesSC.List>
    </FeaturesSC.Root>
  )
}
