import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useStateToggle, useDeviceDetector } from 'shared/hooks';

import { Button, Typography, Icon } from 'shared/components/ui';

import { controlList } from './ControlDescription.mocks';

import { LandingPageSC } from 'shared/index.styles';
import { ControlDescriptionSC } from './ControlDescription.styles';

export const ControlDescriptionSection: FC = () => {
  const { t } = useTranslation();
  const { device } = useDeviceDetector();
  const [showAll, { toggle }] = useStateToggle(false);

  return (
    <ControlDescriptionSC.Root>
      <LandingPageSC.SectionSeparator />
      <Typography.H2>
        {t('lp_control_title')}
      </Typography.H2>
      <Typography.Text styles={ControlDescriptionSC.TextCss}>
        {t('lp_control_text')}
      </Typography.Text>
      <ControlDescriptionSC.CardList>
        {controlList.slice(0, (showAll || device !== 'mobile' ? controlList.length : 3)).map((item, index) => (
          <ControlDescriptionSC.CardContainer key={`LPControlDescription-card-${index}`}>
            <ControlDescriptionSC.CardBody>
              <ControlDescriptionSC.CardLogo>
                <Icon.Image name={item.logo} />
              </ControlDescriptionSC.CardLogo>
              <Typography.H3>
                {t(item.title)}
              </Typography.H3>
              <Typography.Text styles={ControlDescriptionSC.CardTextCss}>
                {t(item.description)}
              </Typography.Text>
            </ControlDescriptionSC.CardBody>
          </ControlDescriptionSC.CardContainer>
        ))}
      </ControlDescriptionSC.CardList>
      {device === 'mobile' && (
        <Button.Outline 
          styles={ControlDescriptionSC.MoreBtnCss}
          onClick={toggle}>
            {showAll ? t('lp_control_less_btn_text') : t('lp_control_more_btn_text')}
        </Button.Outline>
      )}
    </ControlDescriptionSC.Root>
  )
}
