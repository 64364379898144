export type Pricing = {
    id: PricingId;
    label: string;
    percent?: string;
    title: string;
    text: string;
    descriptions: Array<string>;
    submit: string;
}
  
export enum PricingId {
    STANDARD = 'standard',
    ENTERPRISE = 'enterprise',
}
  
export const pricingList: Array<Pricing> = [
    {
        id: PricingId.STANDARD,
        label: 'lp_pricing_one_label',
        percent: '3,9%',
        title: 'lp_pricing_one_title',
        text: 'lp_pricing_one_text',
        descriptions: [
            'lp_pricing_one_description_one',
            'lp_pricing_one_description_two',
            'lp_pricing_one_description_three',
            'lp_pricing_one_description_four',
        ],
        submit: 'lp_pricing_one_button_text',
    }, {
        id: PricingId.ENTERPRISE,
        label: 'lp_pricing_two_label',
        title: 'lp_pricing_two_title',
        text: 'lp_pricing_two_text',
        descriptions: [
            'lp_pricing_two_description_one',
            'lp_pricing_two_description_two',
            'lp_pricing_two_description_three',
            'lp_pricing_two_description_four',
        ],
        submit: 'lp_pricing_two_button_text',
    }
];
