import styled from 'styled-components';

import { Color, MediaQueries } from 'shared/config';

const Wrapper = styled.div`
    overflow: hidden;
    position: relative;
    z-index: 0;
    padding-top: 170px;
    background-color: ${Color.white};

    @media (max-width: ${MediaQueries.tablet}) {
        padding-top: 105px;
    }

    @media (max-width: ${MediaQueries.mobile}) {
        padding-top: 145px;
    }
`;

export const LPLayout = {
    Wrapper
};
