import React, { memo, useRef } from 'react';

import { useParallax } from 'shared/hooks';

import background from '../../../images/feature-illustrations/left-bg.jpg';
import root from '../../../images/feature-illustrations/white-label-root.png'
import timer from '../../../images/feature-illustrations/white-label-timer.png';
import subTimer from '../../../images/feature-illustrations/white-label-sub-timer.jpg';
import notification from '../../../images/feature-illustrations/white-label-notification.png';

import backgroundRetina from '../../../images/feature-illustrations/left-bg@retina.jpg';
import rootRetina from '../../../images/feature-illustrations/white-label-root@retina.png'
import timerRetina from '../../../images/feature-illustrations/white-label-timer@retina.png';
import subTimerRetina from '../../../images/feature-illustrations/white-label-sub-timer@retina.jpg';
import notificationRetina from '../../../images/feature-illustrations/white-label-notification@retina.png';

import { WhiteLabelSC } from './WhiteLabel.styles';

export const WhiteLabelIllustration = memo(() => {
    const ref = useRef<HTMLDivElement>(null);
    const { value } = useParallax(ref);

    return (
        <WhiteLabelSC.Root ref={ref}>
            <WhiteLabelSC.Background
                srcSet={`${background} 1x, ${backgroundRetina} 2x`}
                src={background}
            />
            <WhiteLabelSC.RootImage
                srcSet={`${root} 1x, ${rootRetina} 2x`}
                src={root}
            />
            <WhiteLabelSC.Timer
                srcSet={`${timer} 1x, ${timerRetina} 2x`}
                style={{transform: `translateY(${-value / 12}px)`}}
                src={timer}
            />
            <WhiteLabelSC.Notification
                srcSet={`${notification} 1x, ${notificationRetina} 2x`}
                style={{transform: `translateY(${-value / 12}px)`}}
                src={notification}
            />
            <WhiteLabelSC.SubTimer
                srcSet={`${subTimer} 1x, ${subTimerRetina} 2x`}
                style={{transform: `translateY(${-value / 25}px)`}}
                src={subTimer}
            />
        </WhiteLabelSC.Root>
    )
})
