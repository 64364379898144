import React from 'react';
import { FlattenSimpleInterpolation } from 'styled-components';

import { CloseIconSC } from './CloseIcon.styles';

type NotificationProps = {
    styles?:FlattenSimpleInterpolation;
}

export const CloseIcon:React.FC<NotificationProps> = ({ styles }) => (
    <CloseIconSC.Root styles={styles} />
)