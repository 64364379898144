import React, { createContext, useRef, useState } from 'react';

import { NotificationStatusType, Notification } from 'shared/components/ui';

const TIMER_DURATION = 4000;

type NotificationContextType = {
  showNotification:(type:NotificationStatusType, text:string) => void;
};

export const NotificationContext = createContext<NotificationContextType>({
    showNotification:(type:NotificationStatusType, text:string) => ({}),
});

export const NotificationProvider: React.FC = ({ children }) => {
    const timer = useRef<NodeJS.Timeout | null>(null);
    const [ type, setType ] = useState<NotificationStatusType>('success');
    const [ isOpen, setIsOpen ] = useState<boolean>(false);
    const [ text, setText ] = useState('Something went wrong. Please try again');

    const action = (type:NotificationStatusType, text:string) => {
        setType(type);
        setText(text);
        setIsOpen(true);

        if(timer.current === null) {
            timer.current = global.setTimeout(() => {
                setIsOpen(false);
                timer.current = null;
            }, TIMER_DURATION);
        }
    }

    return (
        <NotificationContext.Provider value={{ showNotification: action }}>
            <Notification isOpen={isOpen} text={text} type={type} onClose={setIsOpen.bind(null, false)} />
            {children}
        </NotificationContext.Provider>
    );
};