import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem('lp_language') || 'de',
    fallbackLng: ['en', 'de', 'fr', 'it'],
    keySeparator: false, // Not using for now.
    interpolation: {
      escapeValue: false // React already safes from xss.
    },
    backend: { loadPath: '/locales/{{lng}}.json' },
  });
