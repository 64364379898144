import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import { Typography, Button, CloseIcon } from 'shared/components/ui';

import modalPicture from '../../../images/modal_picture.png';
import handIcon from '../../../images/icons/hand.svg';
import pencilIcon from '../../../images/icons/pencil.svg';

import { Color } from 'shared/config';

import { PricingModalSC } from './PricingModal.styles';

type PricingModalProps = {
    isOpen: boolean;
    closeModalHandler: () => void;
};

const PhoneNumber = '+41 43 508 98 43';
const EmailAddress = 'jean-paul@mindnow.io';
const DemoLink =
    'https://app.prosperworks.com/public/meeting-scheduler/mindnow%20AG/benedict/293702:c044038e-106c-4860-ac3a-244965018ac9';

export const PricingModal: FC<PricingModalProps> = ({
    isOpen,
    closeModalHandler,
}) => {
    const { t } = useTranslation();

    const navigateToDemoLink = useCallback(() => {
        const win = window.open(DemoLink, '_blank');
        win?.focus();
    }, []);

    return (
        <PricingModalSC.Container
            as={ReactModal}
            isOpen={isOpen}
            className="Modal"
            onRequestClose={closeModalHandler}
            shouldCloseOnOverlayClick
            style={{
                overlay: {
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    position: 'fixed',
                    backgroundColor: Color.modal_overlay,
                },
            }}
        >
            <PricingModalSC.Close onClick={closeModalHandler}>
                <CloseIcon styles={PricingModalSC.CloseIconCss} />
            </PricingModalSC.Close>
            <Typography.H2 styles={PricingModalSC.TitleCss}>
                {t('lp_pricing_modal_title')}
            </Typography.H2>
            <PricingModalSC.ContentContainer>
                <PricingModalSC.Picture src={modalPicture} alt="" />
                <PricingModalSC.CTAContainer>
                    <Button.Outline
                        onClick={() => window.open(`tel:${PhoneNumber}`)}
                    >
                        <PricingModalSC.ButtonIcon
                            src={handIcon}
                            alt="hand icon"
                        />
                        {PhoneNumber}
                    </Button.Outline>
                    <Button.Outline
                        onClick={() => window.open(`mailto:${EmailAddress}`)}
                    >
                        <PricingModalSC.ButtonIcon
                            src={pencilIcon}
                            alt="hand icon"
                            padding="4px"
                        />
                        {EmailAddress}
                    </Button.Outline>
                    <Button.Primary onClick={navigateToDemoLink}>
                        {/* { t('lp_pricing_modal_submit') } */}

                        {t('lp_pricing_modal_submit_update')}
                    </Button.Primary>
                </PricingModalSC.CTAContainer>
            </PricingModalSC.ContentContainer>
        </PricingModalSC.Container>
    );
};
