import styled from 'styled-components';

import { Color, Font, MediaQueries } from 'shared/config';

const Selected = styled.div`
    display: flex;
    align-items: center;
    text-transform: uppercase;
    justify-content: center;

    @media (max-width: ${MediaQueries.mobile}) {
        text-transform: none;
        font-size: 14px;
    }
`;

const Dropdown = styled.ul`
    position: absolute;
    top: 20px;
    opacity: 0;
    padding: 20px 33px 15px 26px;
    left: 50%;
    margin-left: -42px;
    width: 83px;
    list-style: none;
    text-transform: uppercase;
    transform-origin: top;
    transform: scale(0);
    background-color: ${Color.white};
    transition: all 300ms ease-out;
    border-radius: 16px;
    box-shadow: 4px 0px 18px 5px rgba(0,0,0,0.04);

    &:before {
        content: '';
        position: absolute;
        top: -6px;
        left: 50%;
        margin-left: -14px;
        width: 0; 
        height: 0; 
        border-left: 14px solid transparent;
        border-right: 14px solid transparent;
        border-bottom: 8px solid ${Color.white};
    }

    li {
        cursor: pointer;
        text-align: left;
        line-height: 26px;
        transition: color 400ms ease-out;
        &:hover {
            color: ${Color.watermelon};
        }
    }

    @media (max-width: ${MediaQueries.mobile}) {
        box-shadow: none;
        left: 0;
        right: 0;
        margin-left: 0;
        width: 100%;
        border-radius: 0;
        padding: 22px 25px 23px 25px;
        text-transform: none;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
        background-color: transparent;

        &:before {
            display: none;
        }

        li {
            font-size: 14px;
            margin-top: 13px;
            color: ${Color.black};
            &:first-child {
                margin-top: 0;
            }
        }
    }
`;

const Icon = styled.div`
    margin-left: 9px;
    width: 0px;
    height: 0px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid ${Color.black};
    transition: all 300ms ease-out;

    @media (max-width: ${MediaQueries.mobile}) {
        border-top: 6px solid ${Color.white};
        margin-left: 20px;
    }
`;

const Root = styled.div<{isOpen: boolean}>`
    position: relative;
    font-family: ${Font.medium};
    padding: 18px 0;
    cursor: pointer;

    @media (max-width: ${MediaQueries.mobile}) {
        width: 130px;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 52px;
            border-radius: 20px;
            opacity: 0;
            z-index: -1;
            background-color: ${Color.white};
            transition: all 300ms ease-out;
        }
    }

    ${props => props.isOpen && (`
        ${Dropdown} {
            opacity: 1;
            top: 48px;
            transform: scale(1);
        }
        ${Icon} {
            transform: rotate(180deg);
        }

        @media (max-width: ${MediaQueries.mobile}) {

            &:before {
                opacity: 1;
                height: 186px;
            }
            
            ${Dropdown} {
                top: 37px;
            }
            ${Selected} {
                color: ${Color.black}
            }
            ${Icon} {
                border-top: 6px solid ${Color.black};
            }
        }
    `)};
`;

export const LanguageSwitcherSC = {
    Root,
    Selected,
    Dropdown,
    Icon
}
