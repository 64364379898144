import { getClient } from "./client";

import { ResponseDataType } from "shared/types";
import { HeadersType } from "./helpers";

const url = process.env.REACT_APP_API_URL;

const get = <T>(path: string, headers?:HeadersType): Promise<ResponseDataType<T>> => getClient<T>(url + path, 'GET', null, headers);

const remove = <T>(path: string, headers?:HeadersType): Promise<ResponseDataType<T>> => getClient<T>(url + path, 'DELETE', null, headers);

const post = <T>(path: string, body: object, headers?:HeadersType): Promise<ResponseDataType<T>> => getClient<T>(url + path, 'POST', body, headers);

const put = <T>(path: string, body: object, headers?:HeadersType): Promise<ResponseDataType<T>> => getClient<T>(url + path, 'PUT', body, headers);

const patch = <T>(path: string, body: object, headers?:HeadersType): Promise<ResponseDataType<T>> => getClient<T>(url + path, 'PATCH', body, headers);

export const http = {
  get,
  post,
  put,
  remove,
  patch
};