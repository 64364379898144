import styled, { css } from 'styled-components';

import { Color, MediaQueries } from 'shared/config';

const MoreBtnCss = css`
  margin-top: 40px;
  border: none;
  background-color: #F3F6FC;
  span {
    color: ${Color.black} !important;
  }
  
  &:after {
    display: none;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    padding-left: 70px;
    padding-right: 70px;
  }
`;

const TextCss = css`
  max-width: 640px;
  margin-top: 24px;
  
  @media (max-width: ${MediaQueries.tablet}) {
    max-width: 500px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    margin-top: 16px;
  }
`;

const CardTextCss = css`
  margin-top: 16px;
`;

const Root = styled.section`
  padding-top: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${MediaQueries.tablet}) {
    margin-bottom: 0;
    padding-top: 0;
    margin-top: -50px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    margin-top: 0;
  }
`;

const CardList = styled.div`
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  @media (max-width: ${MediaQueries.tablet}) {
    margin-top: 0;
  }
`;

const CardContainer = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  width: 33.3333%;
  margin-top: 80px;

  @media (max-width: ${MediaQueries.tablet}) {
    margin-top: 55px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    width: 100%;
    margin-top: 40px;
  }
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > * {
    text-align: left;
  }
`;

const CardLogo = styled.div`
  width: 64px;
  margin-bottom: 36px;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: ${MediaQueries.tablet}) {
    margin-bottom: 33px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    width: 48px;
    margin-bottom: 22px;
  }
`;

export const ControlDescriptionSC = {
  CardList,
  CardContainer,
  CardBody,
  CardLogo,
  Root,

  TextCss,
  CardTextCss,
  MoreBtnCss
};
