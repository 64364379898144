import styled, { css } from 'styled-components';

import { Color, MediaQueries } from 'shared/config';

const ContainerCSS = css`
    margin-top: 26px;
    max-width: 990px;
    margin-bottom: 122px;
    @media (max-width: ${MediaQueries.tablet}) {
        margin-top: 44px;
    }
    @media (max-width: ${MediaQueries.mobile}) {
        margin-top: 10px;
        margin-bottom: 100px;
    }
`;

const TitleCSS = css`
    text-align: left;
    margin-bottom: 15px;
`;

const SubTitleCSS = css`
    text-align: left;
    margin-bottom: 10px;
`;

const TextBox = styled.div<{marginBottom?: string}>`
    p {
        text-align: left;
    }

    li {
        margin-top: 6px;
        &:first-child {
            margin-top: 0;
        }
    }

    a {
        color: ${Color.black};
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }

    &:not(:last-child) {
        margin-bottom: 35px;
    }

    @media (max-width: ${MediaQueries.mobile}) { 
        &:not(:last-child) {
            margin-bottom: 32px;
        }
    }
`;

export const PrivacyPolicySC = {
    TextBox,
    TitleCSS,
    SubTitleCSS,
    ContainerCSS
};
