import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
    ColoredIconName,
    Icon,
    Button,
    TextLabel,
    Typography,
} from 'shared/components/ui';
import { PricingModal } from 'shared/components/grid';

import { usePageScroll, useStateToggle } from 'shared/hooks';

import priceBG from '../../../images/price-bg.svg';

import { PricingId, pricingList } from './Pricing.mocks';

import { PricingSC } from './Pricing.styles';

export const PricingSection = () => {
    const { t } = useTranslation();
    const [isHelpModalOpen, { turnIn: openModal, turnOff: closeModal }] =
        useStateToggle(false);

    const { scrollTop } = usePageScroll();

    // const navigateToRegistrationPage = useCallback(() => {
    //     window.open(process.env.REACT_APP_DASHBOARD_URL, '_blank');
    // }, []);

    // TODO uncomment when the time comes
    // const handlePricingButtonClick = useCallback(
    //     (pricingId: PricingId) => {
    //         if (pricingId === PricingId.STANDARD) {
    //             navigateToRegistrationPage();
    //             return;
    //         }
    //         openModal();
    //     },
    //     [navigateToRegistrationPage, openModal]
    // );

    const handlePricingButtonClick = useCallback(
        (pricingId: PricingId) => {
            if (pricingId === PricingId.STANDARD) {
                scrollTop(true);
                return;
            }
            openModal();
        },
        [scrollTop, openModal]
    );

    return (
        <PricingSC.Root id="price">
            <PricingModal
                isOpen={isHelpModalOpen}
                closeModalHandler={closeModal}
            />
            <PricingSC.PriceList>
                <PricingSC.Background src={priceBG} alt="" />
                {pricingList.map((item) => (
                    <PricingSC.PriceItem key={item.id}>
                        <PricingSC.PriceBody>
                            <TextLabel styles={PricingSC.LabelCss}>
                                {t(item.label)}
                            </TextLabel>
                            <PricingSC.PriceHead>
                                {item.percent && (
                                    <PricingSC.Percent>
                                        {item.percent}
                                    </PricingSC.Percent>
                                )}
                                <Typography.H3>{t(item.title)}</Typography.H3>
                                <Typography.Text styles={PricingSC.TextCss}>
                                    {t(item.text)}
                                </Typography.Text>
                            </PricingSC.PriceHead>
                            <PricingSC.DescriptionList>
                                {item.descriptions.map((text, index) => (
                                    <PricingSC.DescriptionItem
                                        key={`${item.id}-description-${index}`}
                                    >
                                        <Icon.Image
                                            name={ColoredIconName.check_red}
                                        />
                                        {t(text)}
                                    </PricingSC.DescriptionItem>
                                ))}
                            </PricingSC.DescriptionList>
                            <Button.Primary
                                styles={PricingSC.ButtonCss}
                                onClick={handlePricingButtonClick.bind(
                                    null,
                                    item.id
                                )}
                            >
                                {t(item.submit)}
                            </Button.Primary>
                        </PricingSC.PriceBody>
                    </PricingSC.PriceItem>
                ))}
            </PricingSC.PriceList>
        </PricingSC.Root>
    );
};
