import { css } from 'styled-components';

import { Color, Font } from 'shared/config';

export const TextStyle2 = css`
  font-family: ${Font.black};
  font-size: 48px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: ${Color.black};
`;

export const PageTitleText = css`
  font-family: ${Font.bold};
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: ${Color.black};
`;

export const TextStyle1 = css`
  font-family: ${Font.bold};
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: ${Color.black};
`;

export const ItemTitleText = css`
  font-family: ${Font.bold};
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: ${Color.charcoal_grey};
`;

export const TextStyle5 = css`
  font-family: ${Font.medium};
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${Color.windows_blue};
`;

export const TextStyle4 = css`
  font-family: ${Font.medium};
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${Color.cloudy_blue};
`;

export const TextStyle3 = css`
  font-family: ${Font.medium};
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${Color.black};
`;

export const TextStyle6 = css`
  font-family: ${Font.bold};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${Color.white};
`;

export const TextStyle11 = css`
  font-family: ${Font.medium};
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: ${Color.cloudy_blue_two};
`;

export const TextStyle8 = css`
  font-family: ${Font.regular};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: ${Color.bluey_grey};
`;

export const TextStyle9 = css`
  font-family: ${Font.bold};
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: ${Color.charcoal_grey};
`;

export const TextStyle12 = css`
  font-family: ${Font.bold};
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: ${Color.bluey_grey};
`;

export const TextStyle13 = css`
  font-family: ${Font.regular};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${Color.bluey_grey};
`;

export const LogoText = css`
  font-family: ${Font.bold};
  font-size: 24px;
  line-height: 24px;
  color: ${Color.black};
  white-space: nowrap;
`;

export const SectionTitleText = css`
  font-family: ${Font.bold};
  font-size: 16px;
  line-height: 16px;
  color: ${Color.black};
`;

export const UserDropdownText = css`
  font-family: ${Font.regular};
  font-size: 12px;
  line-height: 16px;
  color: ${Color.charcoal_grey};
`;

export const FormSubLabelText = css`
  font-family: ${Font.regular};
  font-size: 14px;
  line-height: 14px;
  color: ${Color.cloudy_blue};
`;

export const FormInputText = css`
  font-family: ${Font.regular};
  font-size: 14px;
  line-height: 14px;
  color: ${Color.black};
`;

export const FormInputLabelText = css`
  font-family: ${Font.medium};
  font-size: 14px;
  line-height: 14px;
  color: ${Color.charcoal_grey};
`;

export const FormInputSubLabelText = FormSubLabelText;

export const FormInputPostfixText = css`
  font-family: ${Font.regular};
  font-size: 14px;
  line-height: 14px;
  color: ${Color.charcoal_grey};
`;

export const FormInputErrorText = css`
  font-family: ${Font.regular};
  font-size: 14px;
  line-height: 14px;
  color: ${Color.watermelon};
`;

export const TextStyle27 = css`
  font-family: ${Font.bold};
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: ${Color.lightish_blue};
`;

export const TextStyle25 = css`
  font-family: ${Font.regular};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: ${Color.black}
`;

export const DefaultFormSectionText = css`
  font-family: ${Font.bold};
  font-size: 16px;
  line-height: 24px;
`;

export const FormSectionText = css`
  ${DefaultFormSectionText}
  color: ${Color.black}
`;

export const TextStyle14 = css`
  font-family: ${Font.manropeBold};
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${Color.black};
`;

export const TextStyle15 = css`
  font-family: ${Font.regular};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${Color.charcoal_grey};
`;

export const TextStyle16 = css`
  font-family: ${Font.regular};
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: ${Color.black};
`;

export const LPButtonTextStyle = DefaultFormSectionText;

export const LPTitleText = css`
  font-family: ${Font.bold};
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: ${Color.black};
`;

export const LPSubtitleText = css`
  font-family: ${Font.regular};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: ${Color.black};
`;

export const LPBoldSubtitleText = css`
  ${LPSubtitleText}
  font-family: ${Font.bold};
  font-weight: bold;
`;

export const LPCardTitleText = css`
  font-family: ${Font.bold};
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 32px;
  letter-spacing: normal;
  text-align: center;
  color: ${Color.black};
`;

export const ColorPickerLabelText = css`
  font-family: ${Font.regular};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: ${Color.cp_black};
`;
