import styled, { FlattenSimpleInterpolation } from 'styled-components';

const Root = styled.div<{ styles?:FlattenSimpleInterpolation; }>`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 32px;
  padding-right: 32px;

  ${props => props.styles}
`;

export const ContainerSC = {
  Root,
};
