import { useHistory } from 'react-router-dom';
import { LandingPageUrls } from 'shared/urls';

export const useIsLanding = () => {
  const router = useHistory();
  
  const takeMeTo = (section: string, callback: (section: string) => void) => {
    router.push(LandingPageUrls.landingPage);
    
    const timeout = setTimeout(() => {
      section && callback(section);
    }, 870);

    return () => clearTimeout(timeout);
  }

  return { takeMeTo };
}