import styled, { css } from 'styled-components';

import { Color, Font } from 'shared/config';

const HelpBtnCss = css`
  padding: 6px 46px !important;
  color: ${Color.white};
  border-color: ${Color.white};
  min-height: 40px;
`;

const Wrapper = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 410px;

  button {
    transform: translateY(20px);
    font-size: 14px;
    opacity: 0;
    transition: all 0.3s;
  }
`;

const Link = styled.li`
  color: ${Color.white};
  font-size: 20px;
  line-height: 20px;
  font-family: ${Font.medium};
  margin-bottom: 40px;
  opacity: 0;
  transform: translateY(20px);
  transition: all 300ms ease-in-out;
`;

const LangWrapper = styled.div`
  margin-top: 24px;
  opacity: 0;
  transition: opacity 600ms ease-in-out;
`;

const Root = styled.div<{ isOpen:boolean; length:number }>`
  position: fixed;
  visibility: hidden;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 105;
  display: flex;
  padding-top: 170px;
  padding-bottom: 100px;
  overflow: scroll;
  background-color: ${Color.watermelon};
  justify-content: center;
  transition: opacity 300ms ease-in-out;
  ${props => props.isOpen && (`
    opacity: 1;
    visibility: visible;
    ${Link} {
      opacity: 1;
      transform: translateY(0);
      transition: all 350ms ease-in-out;

      ${Array.from(Array(props.length)).map((_, i) => `
        &:nth-child(${(i + 1)}) {
          transition-delay: ${(i + 1) * 150}ms;
        }
      `)}
    }

    ${LangWrapper} {
      opacity: 1;
      transition-delay: 800ms;
    }

    ${Wrapper} {
      button {
        opacity: 1;
        transform: translateY(0);
        transition-delay: 800ms;
      }
    }
  `)}
`;

const Navigation = styled.nav`
  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const MobileMenuSC = {
  Root,
  Wrapper,
  Navigation,
  Link,
  LangWrapper,

  HelpBtnCss
};
