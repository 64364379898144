import React, { memo, useRef } from 'react';

import { useParallax } from 'shared/hooks';

import background from '../../../images/feature-illustrations/right-bg.jpg';
import root from '../../../images/feature-illustrations/analytic-root.png';
import graphOne from '../../../images/feature-illustrations/analytic-graph-one.png';
import graphTwo from '../../../images/feature-illustrations/analytic-graph-two.png';

import backgroundRetina from '../../../images/feature-illustrations/right-bg@retina.jpg';
import rootRetina from '../../../images/feature-illustrations/analytic-root@retina.png';
import graphOneRetina from '../../../images/feature-illustrations/analytic-graph-one@retina.png';
import graphTwoRetina from '../../../images/feature-illustrations/analytic-graph-two@retina.png';

import { AnalyticSC } from './Analytic.styles';

export const AnalyticIllustration = memo(() => {
    const ref = useRef<HTMLDivElement>(null);
    const { value } = useParallax(ref);

    return (
        <AnalyticSC.Root ref={ref}>
            <AnalyticSC.Background
                srcSet={`${background} 1x, ${backgroundRetina} 2x`}
                src={background}
            />
            <AnalyticSC.RootImage
                srcSet={`${root} 1x, ${rootRetina} 2x`}
                src={root}
            />
            <AnalyticSC.GraphOne
                srcSet={`${graphOne} 1x, ${graphOneRetina} 2x`}
                style={{transform: `translateY(${-value / 12}px)`}} src={graphOne}
            />
            <AnalyticSC.GraphTwo
                style={{transform: `translateY(${-value / 12}px)`}}
                srcSet={`${graphTwo} 1x, ${graphTwoRetina} 2x`}
                src={graphTwo}
            />
        </AnalyticSC.Root>
    )
});
