import styled, { css } from 'styled-components';

import { Color, MediaQueries, Font } from 'shared/config';

const ButtonCss = css`
  width: 100%;
  max-width: 270px;
  margin-top: 65px;

  @media (max-width: ${MediaQueries.tablet}) {
    margin-top: 40px;
    max-width: 250px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    margin-top: 27px;
  }
`;

const TextCss = css`
  opacity: .5;
  margin-top: 15px;

  @media (max-width: ${MediaQueries.tablet}) {
    margin-top: 21px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    margin-top: 8px;
  }
`;

const LabelCss = css`
  width: 100%;
  max-width: 160px;
  margin-bottom: 40px;

  @media (max-width: ${MediaQueries.tablet}) {
    max-width: 110px;
    margin-bottom: 40px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    margin-bottom: 24px;
  }
`;

const Root = styled.section`
  position: relative;
  margin-top: 135px;
  width: 100%;
  max-width: 830px;
  margin-right: auto;
  margin-left: auto;

  @media (max-width: ${MediaQueries.tablet}) {
    max-width: 760px;
    margin-top: 80px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    margin-top: 40px;
    padding-top: 60px;
  }
`;

const Background = styled.img`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
`;

const PriceList = styled.div`
  position: relative;
  display: flex;
  min-height: 878px;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  padding-top: 140px;

  @media (max-width: ${MediaQueries.tablet}) {
    min-height: auto;
    padding-top: 120px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    justify-content: center;
    padding-top: 40px;
    margin-left: 0;
    margin-right: 0;
  }
`;

const PriceItem = styled.div`
  padding: 0 10px;
  width: 50%;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    z-index: -1;
    width: 74%;
    height: 100%;
    top: 27px;
    left: 13%;
    opacity: 0.5;
    border-radius: 20px;
    filter: blur(50px);
    background-color: #ccccd3;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    width: 100%;
    padding: 0;
    max-width: 400px;
    margin-bottom: 20px;

    &:before {
      width: 90%;
      left: 5%;
    }
  }
`;

const PriceBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Color.white};
  padding: 47px 40px 52px;
  border-radius: 20px;

  @media (max-width: ${MediaQueries.tablet}) {
    padding: 40px 30px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    padding: 32px 20px;
  }
`;

const PriceHead = styled.div`
  border-bottom: 1px solid #E5E5E6;
  min-height: 200px;
  margin-bottom: 48px;

  @media (max-width: ${MediaQueries.tablet}) {
    min-height: 180px;
    margin-bottom: 40px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    min-height: auto;
    padding-bottom: 24px;
    margin-bottom: 28px;
  }
`;

const Percent = styled.div`
  font-size: 48px;
  line-height: 48px;
  font-family: ${Font.bold};
  text-align: center;
  margin-bottom: 15px;

  @media (max-width: ${MediaQueries.tablet}) {
    font-size: 40px;
    line-height: 40px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    font-size: 32px;
    margin-bottom: 8px;
  }
`;

const DescriptionList = styled.ul`
  width: 100%;
`;

const DescriptionItem = styled.li`
  margin-top: 15px;
  display: flex;
  align-items: center;
  font-family: ${Font.regular};
  img {
    margin-right: 16px;
  }
  &:first-child {
    margin-top: 0;
  }

  @media (max-width: ${MediaQueries.tablet}) {
    font-size: 14px;
    padding-left: 10px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    font-size: 12px;
    margin-top: 7px;
  }
`;

export const PricingSC = {
  Root,
  Background,
  PriceList,
  PriceItem,
  PriceBody,
  PriceHead,
  DescriptionList,
  DescriptionItem,
  Percent,

  ButtonCss,
  TextCss,
  LabelCss
};
