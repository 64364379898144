import React from 'react';
import { FlattenSimpleInterpolation } from 'styled-components';

import { TypographySC } from './Typography.styles';

type TopographyProps = {
    styles?:FlattenSimpleInterpolation;
} 

const H2:React.FC<TopographyProps> = ({ children, styles }) => (
    <TypographySC.H2 styles={styles}>{children}</TypographySC.H2>
)

const H3:React.FC<TopographyProps> = ({ children, styles }) => (
    <TypographySC.H3 styles={styles}>{children}</TypographySC.H3>
)

const H4:React.FC<TopographyProps> = ({ children, styles }) => (
    <TypographySC.H4 styles={styles}>{children}</TypographySC.H4>
)

const H5:React.FC<TopographyProps> = ({ children, styles }) => (
    <TypographySC.H5 styles={styles}>{children}</TypographySC.H5>
)

const Text:React.FC<TopographyProps> = ({ children, styles }) => (
    <TypographySC.Text styles={styles}>{children}</TypographySC.Text>
)

export const Typography = {
    H2,
    H3,
    H4,
    H5,
    Text
}
