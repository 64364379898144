import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, Icon } from 'shared/components/ui';
import { LandingPageSC } from 'shared/index.styles';

import { AdvantagesSC } from './Advantages.styles';
import { AdvantageMocks } from './Advantages.mocks';

export const AdvantagesSection: FC = () => {
  const { t } = useTranslation();

  return (
    <AdvantagesSC.Root id="benefits">
      <LandingPageSC.SectionSeparator />
      <Typography.H2>
        {t('lp_benefit_title')}
      </Typography.H2>
      <Typography.Text styles={AdvantagesSC.TextCss}>
        {t('lp_benefit_text')}
      </Typography.Text>
      <AdvantagesSC.CardList>
        {AdvantageMocks.advantages.map((item, index) => (
          <AdvantagesSC.Card key={`LPAdvantages-box-${index}`}>
            <AdvantagesSC.CardBody>
              <AdvantagesSC.CardLogo>
                <Icon.Image name={item.logoName} />
              </AdvantagesSC.CardLogo>
              <Typography.H3>
                {t(item.title)}
              </Typography.H3>
              <Typography.Text styles={AdvantagesSC.CardTextCss}>
                {t(item.description)}
              </Typography.Text>
            </AdvantagesSC.CardBody>
          </AdvantagesSC.Card>
        ))}
      </AdvantagesSC.CardList>
      <Typography.Text styles={AdvantagesSC.CompanyTitleCss}>
        {t('lp_benefit_companies_title')}
      </Typography.Text>
      <AdvantagesSC.LogoList>
        {AdvantageMocks.companies.map((logo, index) => (
          <AdvantagesSC.LogoItem key={`LPAdvantages-logo-${index}`}>
            <img src={logo} alt="company"  />
          </AdvantagesSC.LogoItem>
        ))}
      </AdvantagesSC.LogoList>
    </AdvantagesSC.Root>
  )
}
