

import marriottLogo from '../../../images/advantage-logos/marriott.svg';
import conrnercardLogo from '../../../images/advantage-logos/cornercard.svg';
import jblLogo from '../../../images/advantage-logos/jbl.svg';
import jelmoliLogo from '../../../images/advantage-logos/jelmoli.svg';
import { ColoredIconName } from 'shared/components/ui';

type Advantage = {
  logoName: ColoredIconName;
  title: string;
  description: string;
}

const advantages: Array<Advantage> = [
  {
    logoName: ColoredIconName.lp_customer,
    title: 'lp_benefit_item_one_title',
    description: 'lp_benefit_item_one_text'
  }, {
    logoName: ColoredIconName.lp_payment,
    title: 'lp_benefit_item_two_title',
    description: 'lp_benefit_item_two_text'
  }, {
    logoName: ColoredIconName.lp_launch,
    title: 'lp_benefit_item_three_title',
    description: 'lp_benefit_item_three_text'
  }, 
];

const companies: Array<string> = [marriottLogo, jelmoliLogo, jblLogo, conrnercardLogo];

export const AdvantageMocks = {
    companies,
    advantages
}
