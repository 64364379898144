import React from 'react';
import { FlattenSimpleInterpolation } from 'styled-components';

import { CloseIcon } from '../CloseIcon/CloseIcon';

import { NotificationSC } from './Notification.styles';

export type NotificationStatusType = 'success' | 'error';

type NotificationProps = {
    styles?:FlattenSimpleInterpolation;
    type:NotificationStatusType;
    isOpen:boolean;
    text:string;
    onClose:() => void;
}

export const Notification:React.FC<NotificationProps> = ({ styles, type, isOpen, text, onClose }) => (
    <NotificationSC.Root isOpen={isOpen} styles={styles}>
        {text}
        <NotificationSC.Icon onClick={onClose}><CloseIcon styles={NotificationSC.CloseIcon} /></NotificationSC.Icon>
    </NotificationSC.Root>
)