import { ColoredIconName } from "shared/components/ui";

type ControlItem = {
    logo: ColoredIconName;
    title: string;
    description: string;
}
  
export const controlList: Array<ControlItem> = [
    {
      logo: ColoredIconName.lp_control_1,
      title: 'lp_control_item_one_title',
      description: 'lp_control_item_one_text',
    }, {
      logo: ColoredIconName.lp_control_2,
      title: 'lp_control_item_two_title',
      description: 'lp_control_item_two_text',
    }, {
      logo: ColoredIconName.lp_control_3,
      title: 'lp_control_item_three_title',
      description: 'lp_control_item_three_text',
    }, {
      logo: ColoredIconName.lp_control_4,
      title: 'lp_control_item_four_title',
      description: 'lp_control_item_four_text',
    }, {
      logo: ColoredIconName.lp_control_5,
      title: 'lp_control_item_five_title',
      description: 'lp_control_item_five_text',
    }, {
      logo: ColoredIconName.lp_control_6,
      title: 'lp_control_item_six_title',
      description: 'lp_control_item_six_text',
    }, {
      logo: ColoredIconName.lp_control_7,
      title: 'lp_control_item_seven_title',
      description: 'lp_control_item_seven_text',
    }, {
      logo: ColoredIconName.lp_control_8,
      title: 'lp_control_item_eight_title',
      description: 'lp_control_item_eight_text',
    }, {
      logo: ColoredIconName.lp_control_9,
      title: 'lp_control_item_nine_title',
      description: 'lp_control_item_nine_text',
    }
];
