import React from 'react'
import { Route, Switch } from 'react-router-dom';

import { Home, Imprint, PrivacyPolicy, Terms } from 'pages';
import { LandingPageUrls } from '../urls';

export const Router = () => (
    <Switch>
        <Route exact path={LandingPageUrls.landingPage}>
            <Home />
        </Route>
        <Route path={LandingPageUrls.privacyPolicy}>
            <PrivacyPolicy />
        </Route>
        <Route path={LandingPageUrls.termsAndConditions}>
            <Terms />
        </Route>
        <Route path={LandingPageUrls.imprintPage}>
            <Imprint />
        </Route>
    </Switch>
)
