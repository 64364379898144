import { useState, useCallback } from 'react';

type UseStateToggle = (initialState?: boolean) => [boolean, {
  turnIn: () => void,
  turnOff: () => void,
  toggle: () => void,
}]

export const useStateToggle: UseStateToggle = (initialState = false) => {
  const [state, setState] = useState(initialState);

  const turnIn = useCallback(() => setState(true), []);
  const turnOff = useCallback(() => setState(false), []);
  const toggle = useCallback(() => setState((state) => !state), []);
  
  return [state, {
    turnIn,
    turnOff,
    toggle,
  }];
};
