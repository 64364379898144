import styled, { css } from 'styled-components';

import { LPAnimationsCS } from 'shared/animations';
import { MediaQueries } from 'shared/config';

const PatternPicStyle = css`
  position: absolute;
  z-index: 0;
  height: auto;
`;

const LeftBg = styled.img`
  ${PatternPicStyle}
  width: 753px;
  left: 50%;
  top: 320px;

  ${LPAnimationsCS.FadeInLeftBg}

  @media (max-width: ${MediaQueries.tablet}) {
    width: 600px;
    top: 330px;
    transform: translateX(-155%);
  }

  @media (max-width: ${MediaQueries.mobile}) {
    width: 532px;
    top: 324px;
    transform: translateX(-122%);
  }
`;

const RightBg = styled.img`
  ${PatternPicStyle}
  width: 902px;
  top: -127px;
  left: 50%;
  margin-left: 108px;

  ${LPAnimationsCS.FadeInRightBg}

  @media (max-width: ${MediaQueries.tablet}) {
    width: 650px;
    top: -275px;
    margin-left: 86px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    width: 624px;
    top: -280px;
    margin-left: -12px;
  }
`;

export const HomeSC = {
    RightBg,
    LeftBg
};
