import React from 'react';

import { AdvantagesSection, ContactSection, ControlDescriptionSection, FeaturesSection, HeroSection, PricingSection } from 'shared/components/sections';

import rightBg from 'shared/images/right-bg.svg';
import leftBg from 'shared/images/left-bg.svg';

import { HomeSC } from './Home.styles';
import { LandingPageSC } from 'shared/index.styles';

export const Home = () => (
  <>
    <HomeSC.RightBg src={rightBg} alt="pattern" />
    <HomeSC.LeftBg src={leftBg} alt="pattern" />
    <LandingPageSC.ContentWrapper>
      <HeroSection />
      <AdvantagesSection />
      <FeaturesSection />
      <ControlDescriptionSection />
      <PricingSection />
      <ContactSection />
    </LandingPageSC.ContentWrapper>
  </>
);
