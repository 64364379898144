import React from 'react';
import { FlattenSimpleInterpolation } from 'styled-components';

import { ContainerSC } from './Container.styles';

type ContainerProps = {
    styles?:FlattenSimpleInterpolation;
};

export const Container: React.FC<ContainerProps> = ({ children, styles }) => (
    <ContainerSC.Root styles={styles}>{children}</ContainerSC.Root>
)
