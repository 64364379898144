import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button, LanguageSwitcher } from 'shared/components/ui';

import { useIsLanding, usePageScroll } from 'shared/hooks';

import { Navigation } from 'shared/mocks';

import { MobileMenuSC } from './MobileMenu.styles';

type LPMobileMenuProps = {
    isOpen:boolean;
    closeMenu: () => void;
}

export const MobileMenu:React.FC<LPMobileMenuProps> = ({ isOpen, closeMenu }) => {
    const { t } = useTranslation();
    const { scrollToSection } = usePageScroll();
    const { takeMeTo } = useIsLanding();

    const {location: { pathname }} = useHistory();
    const isLandingPage = pathname === '/';

    const onLinkClick = (section:string) => {
        closeMenu();
        setTimeout(scrollToSection.bind(null, section), 300);

        if(!isLandingPage) {
            takeMeTo(section, scrollToSection);
        }
    }

    return (
        <MobileMenuSC.Root isOpen={isOpen} length={Navigation.header.length}>
            <MobileMenuSC.Wrapper>
                <MobileMenuSC.Navigation>
                    <ul>
                        {Navigation.header.map((link, index) => (
                            link.section && <MobileMenuSC.Link
                                key={`mobile-menu-link-${index}`}
                                onClick={() => link.section && onLinkClick(link.section)}>
                                    {t(link.translation)}
                            </MobileMenuSC.Link>
                        ))}
                    </ul>
                </MobileMenuSC.Navigation>
                <Button.Outline
                    onClick={() => window.open('https://ecatch.tawk.help', '_blank')}
                    styles={MobileMenuSC.HelpBtnCss}>
                        {t('lp_mobile_menu_button')}
                </Button.Outline>
                <MobileMenuSC.LangWrapper>
                    <LanguageSwitcher />
                </MobileMenuSC.LangWrapper>
            </MobileMenuSC.Wrapper>
        </MobileMenuSC.Root>
    )
}
