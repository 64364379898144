import React from 'react';
import { useTranslation,  } from 'react-i18next';

import { useDeviceDetector, useStateToggle } from 'shared/hooks';

import { LanguageSwitcherSC } from './LanguageSwitcher.styles';

const fullLangname:{[key in string]: string} = {
  en: 'English',
  de: 'Deutsch',
  it: 'Italiana',
  fr: 'Français'
}

export const LanguageSwitcher = () => {
  const { device } = useDeviceDetector();
  const { i18n } = useTranslation();
  const [isOpen, { turnIn, turnOff, toggle }] = useStateToggle(false);

  if(!i18n.languages) {
    return null;
  }

  const onSelectLang = (e:React.MouseEvent<HTMLLIElement, MouseEvent>, lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('lp_language', lang)
    turnOff();
    e.stopPropagation();
  }

  const open = () => {
    turnIn();
  }

  return (
    <LanguageSwitcherSC.Root
      {...(device !== 'desktop' ? { onClick: toggle } : { onMouseEnter: open })}
      isOpen={isOpen}
      onMouseLeave={turnOff}
    >
      <LanguageSwitcherSC.Selected>
        {device === 'mobile' ? fullLangname[i18n.language] : i18n.language}
        <LanguageSwitcherSC.Icon />
      </LanguageSwitcherSC.Selected>
      <LanguageSwitcherSC.Dropdown>
        {i18n.languages.map((lang:string) => (
          lang !== i18n.language && <li key={lang} onClick={e => onSelectLang(e, lang)}>
            {device === 'mobile' ? fullLangname[lang] : lang}
          </li>)
        )}
      </LanguageSwitcherSC.Dropdown>
    </LanguageSwitcherSC.Root>
  )
}
