import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from 'shared/components/grid';
import { Typography } from 'shared/components/ui';

import { PrivacyPolicySC } from './PrivacyPolicy.styles';

export const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <Container styles={PrivacyPolicySC.ContainerCSS}>
      <Typography.H2 styles={PrivacyPolicySC.TitleCSS}>{t('lp_policy_privacy_title')}</Typography.H2>
      <PrivacyPolicySC.TextBox>
        <Typography.Text>
          {t('lp_privacy_policy_title_first_row')}
        </Typography.Text>
        <Typography.Text>
          {t('lp_policy_privacy_title_second_row')}
        </Typography.Text>
      </PrivacyPolicySC.TextBox>

      <PrivacyPolicySC.TextBox>
        <Typography.H3 styles={PrivacyPolicySC.SubTitleCSS}>
          {t('lp_privacy_policy_analytics_title')}
        </Typography.H3>
        <Typography.Text>
          {t('lp_privacy_policy_analytics_first-row')}
        </Typography.Text>
        <Typography.Text>
          {t('lp_privacy_policy_analytics_second_row')}
        </Typography.Text>
      </PrivacyPolicySC.TextBox>

      <PrivacyPolicySC.TextBox>
        <Typography.H3 styles={PrivacyPolicySC.SubTitleCSS}>
          {t('lp_privacy_policy_interaction_title')}
        </Typography.H3>
        <Typography.Text>
          {t('lp_privacy_policy_interaction_first_row')}
        </Typography.Text>
        <Typography.Text>
          {t('lp_privacy_policy_interaction_second_row')}
        </Typography.Text>
      </PrivacyPolicySC.TextBox>

      <PrivacyPolicySC.TextBox>
        <Typography.H3 styles={PrivacyPolicySC.SubTitleCSS}>
          {t('lp_privacy_policy_traffic_title')}
        </Typography.H3>
        <Typography.Text>
          {t('lp_privacy_policy_traffic_first_row')}
        </Typography.Text>
        <Typography.Text>
          {t('lp_privacy_policy_traffic_second_row')}
        </Typography.Text>
      </PrivacyPolicySC.TextBox>

      <PrivacyPolicySC.TextBox>
        <Typography.H3 styles={PrivacyPolicySC.SubTitleCSS}>
          {t('lp_privacy_policy_payments_title')}
        </Typography.H3>
        <Typography.Text>
          {t('lp_privacy_policy_payments_first_row')}
        </Typography.Text>
        <Typography.Text>
          {t('lp_privacy_policy_payments_second_row')}
        </Typography.Text>
      </PrivacyPolicySC.TextBox>

      <PrivacyPolicySC.TextBox>
        <Typography.H3 styles={PrivacyPolicySC.SubTitleCSS}>
          {t('lp_privacy_policy_contacts_messages_title')}
        </Typography.H3>
        <Typography.Text>
          {t('lp_privacy_policy_contacts_messages_first_row')}
        </Typography.Text>
        <Typography.Text>
          {t('lp_privacy_policy_contacts_messages_second_row')}
        </Typography.Text>
      </PrivacyPolicySC.TextBox>

      <PrivacyPolicySC.TextBox marginBottom="20px">
        <Typography.H3 styles={PrivacyPolicySC.SubTitleCSS}>
          {t('lp_privacy_policy_further_title')}
        </Typography.H3>
        <Typography.Text>
          {t('lp_privacy_policy_further_first_row')}
        </Typography.Text>
        <Typography.Text>
          {t('lp_privacy_policy_further_second_row')}
        </Typography.Text>
        <Typography.Text>
          {t('lp_privacy_policy_further_third_row')}
        </Typography.Text>
      </PrivacyPolicySC.TextBox>

      <PrivacyPolicySC.TextBox marginBottom="20px">
        <Typography.H3 styles={PrivacyPolicySC.SubTitleCSS}>
          {t('lp_privacy_policy_contact_details_title')}
        </Typography.H3>
        <ul>
          <li>
            {t('lp_privacy_policy_contact_details_first_row')}
          </li>
          <li>
            MindNow AG
          </li>
          <li>
            Okenstr. 6
          </li>
          <li>
            {t('lp_privacy_policy_contact_details_fourth_row')}
          </li>
          <li>
            {t('lp_privacy_policy_contact_details_fifth_row')}
          </li>
          <li>
            {t('lp_privacy_policy_contact_details_sixth_row')}
            <a href="mailto:hello@mindnow.io">hello@mindnow.io</a>
          </li>
        </ul>
      </PrivacyPolicySC.TextBox>
    </Container>
  );
};
