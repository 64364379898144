import styled, { css } from 'styled-components';

import { MediaQueries } from 'shared/config';
import { LPAnimationsCS } from 'shared/animations';

const Root = styled.div`
    position: relative;
    max-width: 660px;
    width: 100%;
    margin-bottom: 22px;

    ${LPAnimationsCS.FadeUpHeroForm}

    @media (max-width: ${MediaQueries.tablet}) {
        margin-bottom: 24px;
    }

    @media (max-width: ${MediaQueries.mobile}) {
        margin-bottom: 16px;
    }
`;

const InputCSS = css`
    input {
        padding: 32px 256px 32px 40px;
    }

    @media (max-width: ${MediaQueries.tablet}) {
        input {
            padding: 24px 246px 24px 24px;
        }
    }

    @media (max-width: ${MediaQueries.mobile}) {
        input {
            padding: 12px 20px 60px 20px;
        }
    }
`;

const ButtonWrapper = styled.div`
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 5;
    min-width: 190px;

    button {
        min-width: 100%;
    }

    @media (max-width: ${MediaQueries.mobile}) {
        left: 4px;
        top: 50%;
        bottom: 4px;
        button {
            width: 100%;
        }
    }
`;

export const HeroEmailFormSC = {
    Root,
    ButtonWrapper,

    InputCSS,
};
