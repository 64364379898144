import styled, { css } from 'styled-components';

import { Color, LPBoldSubtitleText, LPSubtitleText, LPTitleText, MediaQueries } from 'shared/config';

const ContentWrapper = styled.div`
  transform: scale(1);
  padding-left: 25px;
  padding-right: 25px;
`;

const SectionSeparator = styled.div`
  width: 64px;
  height: 4px;
  margin-bottom: 24px;
  
  border-radius: 16px;
  background-color: ${Color.watermelon};

  @media (max-width: ${MediaQueries.tablet}) {
    margin-bottom: 15px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    width: 40px;
    height: 2px;
  }
`;

type MarginProps = {
  margin?: string;
  marginBottom?: string;
  textAlign?: string;
}

type TitleProps = MarginProps & {
  width?: string;
}

const TitlePropsHandle = css<TitleProps>`
  width: ${({ width }) => width || 'auto'};
  margin: ${({ margin }) => margin && margin};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
  text-align: ${({ textAlign }) => textAlign || 'center'};
`;

const Title = styled.h2<TitleProps>`
  margin: 0;
  
  ${LPTitleText}
  ${TitlePropsHandle}
`;

const SubTitle = styled.p<TitleProps>`
  ${LPSubtitleText}
  ${TitlePropsHandle}
`;

const Label = styled.p<MarginProps>`
  padding: 12px 28px;

  border-radius: 16px;
  background: rgb(241,230,255);
  background: linear-gradient(90deg, rgba(241,230,255,1) 0%, rgba(243,246,252,1) 100%);

  ${LPBoldSubtitleText}
  ${TitlePropsHandle}
`;

export const LandingPageSC = {
  ContentWrapper,
  SectionSeparator,
  Title,
  SubTitle,
  Label,
};
