import React, { FC, memo, useRef } from 'react'
import { useTranslation } from 'react-i18next';

import { useParallax } from 'shared/hooks';

import { HeroEmailForm } from 'shared/components/grid';
import { Typography } from 'shared/components/ui';

import main from '../../../images/hero-main.png';
import preview from '../../../images/hero-preview.png';
import support from '../../../images/hero-support.png';

import mainRetina from '../../../images/hero-main@retina.png';
import previewRetina from '../../../images/hero-preview@retina.png';
import supportRetina from '../../../images/hero-support@retina.png';

import { HeroSC } from './Hero.styles';

export const HeroSection: FC = memo(() => {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { value } = useParallax(ref);

  return (
    <HeroSC.Root>
      <Typography.H2 styles={HeroSC.TitleCss}>
        {t('lp_hero_title')}
      </Typography.H2>
      <HeroEmailForm />
      <Typography.Text styles={HeroSC.TextCss}>
        {t('lp_hero_subtitle')}
      </Typography.Text>
      <HeroSC.OverviewContainer ref={ref}>
        <HeroSC.OverviewImage
          srcSet={`${main} 1x, ${mainRetina} 2x`}
          src={main} alt="overview"
        />
        <HeroSC.SupportImage
          style={{transform: `translateY(${-value / 16}px)`}}
          srcSet={`${support} 1x, ${supportRetina} 2x`}
          src={support}
          alt="support"
        />
        <HeroSC.PreviewImage
          style={{transform: `translateY(${-value / 13}px)`}}
          srcSet={`${preview} 1x, ${previewRetina} 2x`}
          src={preview}
          alt="preview"
        />
      </HeroSC.OverviewContainer>
    </HeroSC.Root>
  )
})
