import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from 'shared/components/grid';
import { Typography } from 'shared/components/ui';

import { ImprintSC } from './Imprint.styles';

export const Imprint = () => {
  const { t } = useTranslation();

  return (
      <Container styles={ImprintSC.ContainerCSS}>
        <Typography.H2 styles={ImprintSC.TitleCSS}>{t('lp_imprint_title')}</Typography.H2>

        <ImprintSC.BoxContainer>
          <ImprintSC.Box>
            <Typography.H3 styles={ImprintSC.SubTitleCSS}>{t('lp_imprint_contact_address')}</Typography.H3>
            <ul>
              <li>mindnow AG</li>
              <li>Okenstr. 6</li>
              <li>8037 {t('lp_imprint_town')}</li>
              <li>{t('lp_imprint_state')}</li>
              <li><a href="mailto:">hello@mindnow.io</a></li>
            </ul>
          </ImprintSC.Box>

          <ImprintSC.Box>
            <Typography.H3 styles={ImprintSC.SubTitleCSS}>{t('lp_imprint_auth_representatives')}</Typography.H3>
            <ul>
              <li>Jakob Heval Kaya</li>
              <li>Daniel Grossen</li>
              <li>Lorenz Furrer</li>
              <li>Klaus Abele</li>
            </ul>
          </ImprintSC.Box>
        </ImprintSC.BoxContainer>

        <ImprintSC.BoxContainer>
          <ImprintSC.Box>
            <Typography.H3 styles={ImprintSC.SubTitleCSS}>{t('lp_imprint_comm_register')}</Typography.H3>
            <ul>
              <li>{t('lp_imprint_company_name')}: mindnow AG</li>
              <li>{t('lp_imprint_number')}: CHE-271.622.601</li>
              <li>{t('lp_imprint_office')}</li>
            </ul>
          </ImprintSC.Box>

          <ImprintSC.Box>
            <Typography.H3 styles={ImprintSC.SubTitleCSS}>{t('lp_imprint_tax_number_title')}</Typography.H3>
            <Typography.Text styles={ImprintSC.ParagraphCSS}>CHE-271.622.601 {t('lp_imprint_tax_number')}</Typography.Text>
          </ImprintSC.Box>
        </ImprintSC.BoxContainer>

        <Typography.H3 styles={ImprintSC.SubTitleCSS}>{t('lp_imprint_disclaimer')}</Typography.H3>

        <ImprintSC.DisclaimerContainer>
          <Typography.Text styles={ImprintSC.ParagraphCSS}>{t('lp_imprint_disclaimer_first_row')}</Typography.Text>
          <Typography.Text styles={ImprintSC.ParagraphCSS}>{t('lp_imprint_disclaimer_second_row')}</Typography.Text>
          <Typography.Text styles={ImprintSC.ParagraphCSS}>{t('lp_imprint_disclaimer_third_row')}</Typography.Text>
        </ImprintSC.DisclaimerContainer>

        <ImprintSC.DefaultContainer>
          <Typography.H3 styles={ImprintSC.SubTitleCSS}>{t('lp_imprint_liability_title')}</Typography.H3>
          <Typography.Text styles={ImprintSC.ParagraphCSS}>{t('lp_imprint_liability')}</Typography.Text>
        </ImprintSC.DefaultContainer>

        <ImprintSC.DefaultContainer>
          <Typography.H3 styles={ImprintSC.SubTitleCSS}>{t('lp_imprint_copyrights_title')}</Typography.H3>
          <Typography.Text styles={ImprintSC.ParagraphCSS}>{t('lp_imprint_copyrights')}</Typography.Text>
        </ImprintSC.DefaultContainer>
      </Container>
  );
};
