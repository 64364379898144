import React from 'react';
import { FlattenSimpleInterpolation } from 'styled-components';

import { TextLabelSC } from './TextLabel.styles';

type TextLabelProps = {
    styles?:FlattenSimpleInterpolation;
}

export const TextLabel:React.FC<TextLabelProps> = ({ children, styles }) => (
    <TextLabelSC.Root styles={styles}>
        {children}
    </TextLabelSC.Root>
)
