import React, { useCallback, useEffect, useRef, useState } from 'react';

import { MediaSizes } from '../config';

type Params = {
    elementStart:number;
    elementEnd:number;
}

export const useParallax = (ref:React.MutableRefObject<HTMLDivElement | null>) => {
    const [value, setValue] = useState<number>(0);
    const params = useRef<Params>();

    const getParams = useCallback(() => {
        if(ref.current) {
            const { top, height } = ref.current?.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const start =  top - windowHeight;
            const end = top + height;
            
            params.current = { elementStart: start, elementEnd: end };
        }
    }, [ref])

    const handleResize = () => {
        if(params.current) {
            const scroll = window.pageYOffset;
            const { elementEnd, elementStart } = params.current;

            if(elementStart < scroll && elementEnd > scroll) {
                setValue(scroll - elementStart);
            }
        }
    } 

    useEffect(() => {
        if(ref && window.innerWidth > MediaSizes.tablet) {
            getParams();
            handleResize();
            window.addEventListener('scroll', handleResize, false);
        }
        return () => window.removeEventListener('scroll', handleResize, false);
    }, [ref, getParams])

	return { value };
};
