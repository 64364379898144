import styled, { css } from 'styled-components';

import { MediaQueries } from 'shared/config';

const TitleCss = css`
  max-width: 500px;

  @media (max-width: ${MediaQueries.tablet}) {
    max-width: 600px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    max-width: 330px;
  }
`;

const ItemTitleCss = css`
  margin-top: 24px;
  margin-bottom: 39px;

  @media (max-width: ${MediaQueries.tablet}) {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 17px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 15px;
    margin-top: 17px;
  }
`;

const Root = styled.section`
  padding-top: 140px;
  margin-left: -25px;
  margin-right: -25px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 56px;

  @media (max-width: ${MediaQueries.tablet}) {
    padding-top: 120px;
    margin-top: -40px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    padding-top: 95px;
    margin-top: -50px;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 50%;
  height: 100%;
  width: calc(100vw / 2);

  @media (max-width: ${MediaQueries.mobile}) {
    position: initial;
    width: 100%;
    height: 320px;
  }
`;

const Body = styled.div`
  width: 50%;
  padding-left: 6.5%;
  padding-top: 133px;
  & > * {
    text-align: left;
  }

  @media (max-width: ${MediaQueries.tablet}) {
    padding-top: 90px;
    padding-left: 4.4%;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    width: 100%;
    padding-left: 0;
    padding-top: 33px;
  }
`;

const Item = styled.div`
  height: 770px;
  display: flex;
  max-width: 1170px;
  padding-left: 25px;
  padding-right: 25px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-top: 95px;
  justify-content: flex-end;

  &:first-child {
    margin-top: 0;
  }

  &:nth-child(even) {
    justify-content: flex-start;
    ${Body} {
      padding-right: 6.5%;
      padding-left: 0;
    }
    ${ImageWrapper} {
      left: 50%;
      right: initial;
    }
  }

  @media (max-width: ${MediaQueries.tablet}) {
    height: 530px;
    margin-top: 0;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    flex-direction: column;
    height: auto;
    margin-top: 80px;
  }
`;

const List = styled.div`
  margin-top: 78px;
  margin-bottom: 28px;

  @media (max-width: ${MediaQueries.tablet}) {
    margin-top: 52px;
    margin-bottom: 0;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    margin-top: 40px;
  }
`;

export const FeaturesSC = {
  Root,
  List,
  Item,
  ImageWrapper,
  Body,
  TitleCss,
  ItemTitleCss
}
