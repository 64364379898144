import { http } from 'api/http';
import {
    ContactRequestValues,
    EmailContactRequestValues,
    ResponseDataType,
} from 'shared/types';

const subscribe = async (
    data: ContactRequestValues | EmailContactRequestValues
): Promise<ResponseDataType<null>> => {
    return http.post<null>('/customers/contact/', data);
};

export const createSubscriptionService = () => ({
    subscribe,
});
