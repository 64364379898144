import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';

import {
    Typography,
    Button,
    InputError,
    FormElement,
} from 'shared/components/ui';

import { contactFormValidationSchema } from './validation';

import { NotificationContext } from 'shared/providers';

import { SubscriptionType } from 'shared/types';

import handIcon from '../../../images/icons/hand.svg';
import pencilIcon from '../../../images/icons/pencil.svg';

import { ContactSectionSC } from './Contact.styles';
import { LandingPageSC } from 'shared/index.styles';
import { api } from 'api';

const formInitialValues = {
    email: '',
    message: '',
    first_name: '',
    last_name: '',
    company: '',
};

export const ContactSection = () => {
    const { showNotification } = useContext(NotificationContext);
    const { t } = useTranslation();
    // Add state to track form submission
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = async (
        values: SubscriptionType,
        formikHelpers: FormikHelpers<SubscriptionType>
    ) => {
        setIsSubmitting(true);
        try {
            const { status } = await api.subscription.subscribe(values);

            if (status === 200) {
                formikHelpers.resetForm();
                showNotification('success', t('lp_form_success'));
            }
        } catch (error) {
            // Handle error if needed
            console.error('Form submission error:', error);
            showNotification('error', t('lp_form_error'));
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <ContactSectionSC.Root id="demo">
            <LandingPageSC.SectionSeparator />
            <Typography.H2>{t('lp_contact_title')}</Typography.H2>
            <Typography.Text styles={ContactSectionSC.TextCss}>
                {t('lp_contact_subtitle')}
            </Typography.Text>
            <Button.Outline
                styles={ContactSectionSC.PhoneBtnCss}
                // onClick={() => window.open('tel:+1800229933')}
                onClick={() => window.open('tel:+41 43 508 98 43')}
            >
                <ContactSectionSC.HandIcon src={handIcon} alt="hand icon" />
                {/* +41 23 456 78 90 */}
                +41 43 508 98 43
            </Button.Outline>

            <ContactSectionSC.Separate>
                <ContactSectionSC.PencilIcon
                    src={pencilIcon}
                    alt="pencil icon"
                />
            </ContactSectionSC.Separate>

            <Formik
                initialValues={formInitialValues}
                validationSchema={contactFormValidationSchema}
                onSubmit={onSubmit}
                isInitialValid={false}
            >
                {({
                    values,
                    errors,
                    touched,
                    isValid,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                }) => (
                    <ContactSectionSC.Form onSubmit={handleSubmit}>
                        <ContactSectionSC.InputLine>
                            <ContactSectionSC.InputWrapper>
                                <FormElement.Input
                                    name="first_name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.first_name}
                                    placeholder={t(
                                        'lp_contact_first_name_label'
                                    )}
                                />
                                {errors.first_name && touched.first_name && (
                                    <InputError>
                                        {t(errors.first_name)}
                                    </InputError>
                                )}
                            </ContactSectionSC.InputWrapper>
                            <ContactSectionSC.InputWrapper>
                                <FormElement.Input
                                    name="last_name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.last_name}
                                    placeholder={t('lp_contact_surname_label')}
                                />
                                {errors.last_name && touched.last_name && (
                                    <InputError>
                                        {t(errors.last_name)}
                                    </InputError>
                                )}
                            </ContactSectionSC.InputWrapper>
                        </ContactSectionSC.InputLine>
                        <ContactSectionSC.InputLine>
                            <ContactSectionSC.InputWrapper>
                                <FormElement.Input
                                    name="company"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.company}
                                    placeholder={t(
                                        'lp_contact_componies_label'
                                    )}
                                />
                                {errors.company && touched.company && (
                                    <InputError>{t(errors.company)}</InputError>
                                )}
                            </ContactSectionSC.InputWrapper>
                            <ContactSectionSC.InputWrapper>
                                <FormElement.Input
                                    name="email"
                                    type="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    placeholder={t('lp_contact_email_label')}
                                />
                                {errors.email && touched.email && (
                                    <InputError>{t(errors.email)}</InputError>
                                )}
                            </ContactSectionSC.InputWrapper>
                        </ContactSectionSC.InputLine>
                        <ContactSectionSC.TextareaWrapper>
                            <FormElement.Textarea
                                name="message"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.message}
                                placeholder={t('lp_contact_message_label')}
                                rows={5}
                            />
                            {errors.message && touched.message && (
                                <InputError>{t(errors.message)}</InputError>
                            )}
                        </ContactSectionSC.TextareaWrapper>
                        <Button.Primary
                            disabled={!isValid || isSubmitting}
                            styles={ContactSectionSC.SubmitBtnCss}
                        >
                            {isSubmitting ? t('lp_contact_submitting_text') : t('lp_contact_submit_text')}
                        </Button.Primary>
                    </ContactSectionSC.Form>
                )}
            </Formik>
        </ContactSectionSC.Root>
    );
};
