import { ResponseDataType } from "shared/types";
import { getHeaders, HeadersType } from "./helpers";

type MethodsType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

type OptionsType = {
    method:MethodsType;
    headers:HeadersType;
    body?:any;
}

export const getClient = async<T>(url:string, method:MethodsType, body?:any, headers:HeadersType = {}) => {
    const options:OptionsType = {
        method: method,
        headers: getHeaders(headers)
    }

    if(body) options['body'] = JSON.stringify(body);

    const response = await fetch(url, options);

    let data = null;

    try {
        data = await response.json();
    } catch(e) { }

    const result:ResponseDataType<T> = {
        status: response.status,
        error: null,
        data
    }

    if(!(result.status === 201 || result.status === 200)) {
        result.error = data;
        result.data = null;
    }

    return result;
}