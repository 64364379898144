import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as ReactLink, useHistory } from 'react-router-dom';

import { ColoredIconName, Typography, Icon } from 'shared/components/ui';

import { useIsLanding, usePageScroll } from 'shared/hooks';
import { Navigation, NavigationItem } from 'shared/mocks';

import { FooterSC } from './Footer.styles';

const payments: Array<ColoredIconName> = [
  ColoredIconName.visa, ColoredIconName.mastercard, ColoredIconName.amex, ColoredIconName.apple, ColoredIconName.android,
];

export const Footer: FC = () => {
  const { t } = useTranslation();

  return (
    <FooterSC.Root>
      <FooterSC.Footer>
        <FooterSC.Content>
          <FooterSC.Description>
            <Typography.H2 styles={FooterSC.DescriptionTitleCss}>
              {t('lp_footer_title')}
            </Typography.H2>
            <Typography.Text styles={FooterSC.DescriptionTextCss}>
              {t('lp_footer_description')}
            </Typography.Text>
          </FooterSC.Description>

          <FooterSC.Navigation>
            <ul>
              {Navigation.footer.map((item, index) => (
                <Link key={`Footer-main-nav-item-${index}`} data={item} external>{t(item.translation)}</Link>
              ))}
            </ul>
          </FooterSC.Navigation>

          <FooterSC.Navigation isSub>
            <ul>
              {Navigation.footerSubNav.map((item, index) => (
                <Link key={`Footer-main-sub-nav-item-${index}`} data={item}>{t(item.translation)}</Link>
              ))}
            </ul>
          </FooterSC.Navigation>

          <FooterSC.Payments>
            <FooterSC.PaymentsWrapper>
              <FooterSC.PaymentsTitle>
                {t('lp_footer_payment_title')}
              </FooterSC.PaymentsTitle>
              <FooterSC.PaymentsList>
                {payments.map((image, index) => <Icon.Image name={image} key={`LPFooter-image-${index}`} /> )}
              </FooterSC.PaymentsList>
            </FooterSC.PaymentsWrapper>
          </FooterSC.Payments>

        </FooterSC.Content>
        <FooterSC.Bottom>
          <FooterSC.MadeBy>
            {'Made with <3 by mindnow'}
          </FooterSC.MadeBy>
          <FooterSC.Stripe>
            <span>{t('lp_footer_stripe')}</span>
            <Icon.Image name={ColoredIconName.stripe} />
          </FooterSC.Stripe>
        </FooterSC.Bottom>
      </FooterSC.Footer>
    </FooterSC.Root>
  );
}

type LinkProps = {
  key:string;
  data:NavigationItem;
  external?:boolean
}

const Link:React.FC<LinkProps> = ({ children, data, external = false }) => {
  const { scrollToSection } = usePageScroll();
  const { takeMeTo } = useIsLanding();
  const {location: { pathname }}  = useHistory();

  const isLandingPage = pathname === '/';

  if(data.section) {
    return (
      <li onClick={() => isLandingPage
        ? data.section && scrollToSection(data.section)
        : data.section && takeMeTo(data.section, scrollToSection)
    }>
        <span>{children}</span>
      </li>
    )
  }

  if(data.url) {
    return (
      <li>
          {external && <a href={data.url}>{children}</a>}
          {!external && (
            <ReactLink to={data.url}>{children}</ReactLink>
          )}
      </li>
    )
  }

  return null;
}
