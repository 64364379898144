import React from 'react';

import { Container } from 'shared/components/grid';
import { Typography } from 'shared/components/ui';

import { TermsSC } from './Terms.styles';

export const Terms = () => (
    <Container styles={TermsSC.ContainerCSS}>
        <Typography.H2>Terms and Conditions and<br />the Privacy Statement</Typography.H2>
        <Typography.H3 styles={TermsSC.SubTitleCSS}>Teilnahmebedingungen</Typography.H3>
        <Typography.H4>Präambel</Typography.H4>
        <Typography.Text>
            Die Mindnow AG (nachfolgend „Mindnow" oder “Betreiberin”) ist Betreiberin der Website www.e-catch.ch. Mindnow führt die 
            nachfolgend beschriebene Auktionsplattform unter der Bezeichnung ECATCH durch (nachfolgend „Auktionsplattform“). Mit der 
            Registrierung auf der Auktionsplattform stimmt der Nutzer folgenden Teilnahmebedingungen zu. Der Nutzer verpflichtet sich 
            alle Angaben wahrheitsgetreu zu machen. Zusätzlich zu den vorliegenden Teilnahmebedingungen kommen etwaige Bedingungen bei 
            einzelnen Auktionen oder Angeboten zur Anwendung, welche auf der jeweiligen Seite vermerkt sind.
        </Typography.Text>
        <Typography.H3>1. Dienstleistung</Typography.H3>
        <Typography.H4>1.1 Allgemeines</Typography.H4>
        <Typography.Text>
            Die Dienstleistung der Auktionsplattform umfasst Auktionen und Kaufangebote. 
            Mindnow behält es sich vor Auktionen und Kaufangebote jederzeit zu entfernen.
        </Typography.Text>
        <Typography.H5>1.1.1 Auktionen</Typography.H5>
        <Typography.Text>
            Bei der Auktion gibt der Teilnehmer einen Preis (“Gebot”) an für welchen er das Produkt kaufen würde. 
            Das Gebot gilt als verbindlicher Kaufentscheid und ein Betrag in der Höhe des Gebotes muss an Mindnow überwiesen 
            werden, wenn das Gebot am Ende der Laufzeit der Auktion am höchsten ist und der Teilnehmer von Mindnow als 
            Gewinner der Auktion bestimmt wurde. Die Annahme dieses Gebots ist Mindnow überlassen. Ein Rücktritt vom Gebot 
            ist nicht möglich. Ein Teilnehmer kann sein Gebot während der Laufzeit der Auktion stetig erhöhen aber nicht senken.
            <br />
            Auktionen sind zeitlich limitiert. Gebote können nur während der Laufzeit abgegeben werden. 
            Falls nicht anders vermerkt, steht eine limitierte Anzahl des angebotenen Produktes zum Verkauf.
            <br />
            Auf der Auktionsseite ist angegeben, wer als Verkäufer des Produkts auftritt. Es kann dies Mindnow oder 
            eine andere Firma sein. Die angegebene Firma ist verantwortlich für sämtliche Ansprüche des Kunden, 
            welche das Produkt betreffen, wie zum Beispiel Beschädigung beim Transport, Rückgabe und Garantie. 
            (Liste nicht abschliessend.)
        </Typography.Text>
        <Typography.H5>1.1.2 Kaufangebote</Typography.H5>
        <Typography.Text>
            Kaufangebote sind zeitlich limitiert. In der Regel gilt solange Vorrat. 
            Beim Betätigen des Kaufbuttons nimmt der Nutzer das Kaufangebot verpflichtend an.
        </Typography.Text>

        <Typography.H4>1.2 Vertragsgegenstand und Plattformfunktionen E-Catch für Immobilien</Typography.H4>
        <Typography.Text>
            Die vorliegende Vereinbarung regelt die Bedingungen für die Nutzung der Plattform "Immocatch" (nachfolgend "Plattform"), 
            welche dazu dient, Immobilien mittels Online-Auktionen zu veräußern. Gemäß den einschlägigen Bestimmungen des Schweizer
            Immobilien-Gesetzes erfolgt der Erwerb von Immobilien im Rahmen dieser Auktionen nicht unter verpflichtendem Charakter, 
            sondern gewährt dem Bieter das exklusive Recht, das bei einer Auktion erfolgreichste Angebot zur Akquisition der Immobilie zu nutzen.
        </Typography.Text>
        <Typography.H5>1.2.1 Manueller Verkaufsprozess</Typography.H5>
        <Typography.Text>
            Der Prozess des Verkaufs der Immobilie erfolgt manuell und obliegt der Verantwortung des Verkäufers der betreffenden 
            Immobilie. Mindnow agiert in keinerlei Weise als Teilnehmer oder Vermittler im tatsächlichen Verkaufsvorgang der Immobilie. 
            Die Funktion von Mindnow beschränkt sich strikt auf die Bereitstellung der technologischen Plattform für die Durchführung von 
            Online-Auktionen von Immobilien.
        </Typography.Text>
        <Typography.H5>1.2.2Haftungsbegrenzung von Mindnow</Typography.H5>
        <Typography.Text>
            Es sei ausdrücklich festgehalten, dass Mindnow keinerlei Haftung für die Verkaufsabwicklung der Immobilien über die Plattform übernimmt. 
            Insbesondere ist Mindnow nicht für Vertragsabschlüsse, Verkaufsbedingungen, rechtliche Implikationen oder Sachverhalte im Zusammenhang mit 
            den verkauften Immobilien verantwortlich. Jegliche Ansprüche, Beschwerden oder Rechtsangelegenheiten, die sich aus oder im Zusammenhang mit 
            dem Verkauf einer Immobilie ergeben, liegen in der alleinigen Zuständigkeit und Verantwortung der beteiligten Parteien, insbesondere des Verkäufers 
            und des erfolgreichen Bieters.
            Diese Vereinbarung unterstreicht eindeutig die begrenzte Rolle und Verantwortung von Mindnow als Anbieter der Plattform und stellt sicher, 
            dass jegliche rechtlichen und operativen Aspekte des Immobilienverkaufs außerhalb des Wirkungsbereichs von Mindnow liegen.
        </Typography.Text>

        <Typography.H3>2. Online-Teilnahme</Typography.H3>
        <Typography.Text>
            Für die Nutzung dieses Services ist die vollständige und richtige Angabe der E-Mail-Adresse, des Geschlechts, 
            des Alters und der Interessen der bietenden Person anzugeben. Sämtliche Personenangaben müssen der Wahrheit entsprechen. 
            Andernfalls kann ein Ausschluss von der Auktionsplattform erfolgen. Ebenfalls werden sämtliche Verträge, wie zum Beispiel Verträge 
            zum Kauf oder zur Ersteigerung, nichtig. Weitere Informationen über Art, Umfang, Ort und Zweck der Erhebung, Verarbeitung und Nutzung 
            der erforderlichen personenbezogenen Daten finden sich in der Datenschutzerklärung auf der Webseite. Diese bildet einen integralen 
            Bestandteil dieser Teilnahmebedingungen. Die Kommunikation mit den Teilnehmern erfolgt ausschliesslich über die angegebene E-Mail-Adresse.
        </Typography.Text>
        <Typography.H3>3. Teilnahmeberechtigung</Typography.H3>
        <Typography.Text>
            Teilnahmeberechtigt sind natürliche Personen älter als 18 Jahre mit Wohnsitz in der Schweiz. 
            Mitarbeiter der Mindnow und aller beteiligter Agenturen sowie deren im gemeinsamen Haushalt 
            lebenden Familienangehörige sind von der Teilnahme ausgeschlossen.
        </Typography.Text>
        <Typography.H3>4. Datenschutz & personenbezogene Daten</Typography.H3>
        <Typography.Text>
            Die Erhebung und die Bearbeitung der persönlichen Daten der Teilnehmer durch Mindnow ist in der Datenschutzerklärung erläutert. 
            Diese bildet einen integralen Vertragsbestandteil dieser Bedingungen. Die Datenschutzerklärung ist auf der Webseite abrufbar.
            <br />
            Der Teilnehmer willigt hiermit in die Speicherung der von ihm im Rahmen der Nutzung der Website eingegebenen 
            personenbezogenen Daten ein. Dies gilt auch für die Speicherung der IP-Adressen, die bei jeder Nutzung 
            der Website übermittelt werden. Der Teilnehmer willigt zudem in die Nutzung seiner personenbezogenen Daten 
            für die Personalisierung von auf der Website geschalteten Werbeanzeigen und Produktangeboten ein. Der 
            Teilnehmer ist ferner damit einverstanden, dass Werbeanzeigen Dritter, gleich welcher Art, auf seinen 
            Profilseiten geschaltet werden. Der Kunde hat jederzeit das Recht, die Einwilligung auf Werbung zu widerrufen. 
            Die Nutzung der Website macht die Erhebung, Verarbeitung und Nutzung personenbezogener Daten durch 
            die Betreiberin unumgänglich. Die Betreiberin versichert, alle gespeicherten Daten sorgsam zu 
            behandeln und ausschliesslich im Rahmen der datenschutzrechtlichen Einwilligungen der Teilnehmer zu 
            verarbeiten. Eine darüber hinausgehende Nutzung personenbezogener Daten erfolgt durch die Betreiberin nur, 
            sofern dies gesetzlich zulässig ist oder der Teilnehmer vorab eingewilligt hat. Der Teilnehmer erklärt 
            sich ferner damit einverstanden, dass die Betreiberin personenbezogene Daten der Teilnehmer für Direktmarketingzwecke 
            benutzt. Dazu zählt die werbliche Ansprache der Nutzerin per E-Mail und per Post. Mit der Teilnahme an einer 
            Auktion willigen Sie dazu ein, dass ihre Daten mit dem Anbieter der Auktion geteilt werden. Der Anbieter dieser Auktion 
            kann diese Daten zu eigenen Werbezwecken nutzen. Die Abmeldung ist jederzeit möglich. Der Einwilligung in die Erhebung, 
            Verarbeitung und Nutzung kann jederzeit unter Mitteilung an die Betreiberin an
            <br />
            Mindnow AG<br />
            Datenschutz<br />
            Okenstrasse 6<br />
            8037 Zürich<br />
            widersprochen werden.
        </Typography.Text>
        <Typography.H3>5. Schlussbestimmungen</Typography.H3>
        <Typography.Text>
            Mindnow behält sich vor, den Zeitraum der Auktion zu verkürzen oder diese, ohne Verkauf 
            eines Produktes an jegliche Gewinner, einzustellen (zum Beispiel wenn, wider Erwarten, 
            die Verfügbarkeit eines Produktes ausfällt, bei einer Einstellung des zugrunde liegenden Service 
            oder bei einem groben Missbrauch wie zum Beispiel Hacker-Angriff auf die Webseite / Web-Plattform / Server). 
            Mindnow behält sich bei Verdacht der Beeinträchtigungen der Gewinnerwahl durch Manipulation oder andere unsachgemässe 
            oder unfaire Mittel oder bei Vorliegen sonstiger berechtigter Bedenken (Verstoss gegen Teilnahmebedingungen wie 
            beispielsweise durch technische Manipulation, etc.) das Recht vor, Teilnehmer auszuschliessen, bereits zuerkannte 
            Käufe abzuerkennen oder zurückzufordern und Ersatzgewinner zu bestimmen. Auktionsgewinne können nicht übertragen, 
            nicht umgetauscht oder in bar ausbezahlt werden. Der Rechtsweg ist ausgeschlossen. Über die Zuteilung der Gewinner 
            wird keine Korrespondenz geführt. Im Falle einer irrtümlich falschen Publikation sind für die Kaufberechtigung 
            ausschliesslich die Auktionsergebnisse massgebend. Ebenso verhält es sich bei einer durch technische Fehler erhaltene 
            Gewinnbenachrichtigung oder einer falschen Gewinnanzeige auf der Plattform. Vorbehaltlich anderer gesetzlicher 
            Bestimmungen untersteht die Beziehung den Parteien ausschliesslich materiellem schweizerischem Recht. Ausschliesslicher 
            Gerichtsstand für alle zwischen den Parteien in Bezug auf einen Kauf oder gewonnene Auktion stehenden Streitigkeiten ist 
            vorbehalten anderslautender gesetzlicher Bestimmungen Zürich, Kanton Zürich.
            <br />
            Mindnow AG, Zürich, November 2017
        </Typography.Text>
        <Typography.H3 styles={TermsSC.SubTitleCSS}>Datenschutzbestimmungen</Typography.H3>
        <Typography.Text>
            Wir, die Mindnow AG, Okenstrasse 6, 8037 Zürich (nachfolgend „Mindnow" oder “Betreiberin”) sind Betreiberin der 
            Webseite www.e-catch.ch (nachfolgend “Webseite”) und der darauf angebotenen Dienste und somit verantwortlich für 
            die Erhebung, Verarbeitung und Nutzung Ihrer persönlichen Daten und die Vereinbarkeit der Datenbearbeitung mit Schweizer 
            Recht. Ihr Vertrauen ist uns wichtig, darum nehmen wir das Thema Datenschutz ernst und achten auf entsprechende Sicherheit. 
            Selbstverständlich beachten wir die gesetzlichen Bestimmungen des Bundesgesetzes über den Datenschutz (DSG), der Verordnung 
            zum Bundesgesetz über den Datenschutz (VDSG), des Fernmeldegesetztes (FMG) und anderer datenschutzrechtlicher Bestimmungen. 
            Damit Sie wissen, welche personenbezogenen Daten wir von Ihnen erheben und für welche Zwecke wir sie verwenden, nehmen Sie 
            bitte die nachstehenden Informationen zur Kenntnis.
        </Typography.Text>
        <Typography.H3>1. Umfang und Zweck der Erhebung, Verarbeitung und Nutzung personenbezogener Daten</Typography.H3>
        <Typography.H4>1.1 Beim Besuch der Webseite</Typography.H4>
        <Typography.Text styles={TermsSC.ClearParagraphMargin}>
            Beim Besuch unserer Website speichern unsere Server temporär jeden Zugriff in einer Protokolldatei. Folgende Daten 
            werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung nach spätestens drei Monaten von uns gespeichert:
        </Typography.Text>
        <ul>
            <li>die IP-Adresse des anfragenden Rechners,</li>
            <li>das Datum und die Uhrzeit des Zugriffs,</li>
            <li>der Name und die URL der abgerufenen Datei,</li>
            <li>die Website, von der aus der Zugriff erfolgte,</li>
            <li>das Betriebssystem Ihres Rechners und der von Ihnen verwendete Browser.</li>
        </ul>
        <Typography.Text>
            Die Erhebung und Verarbeitung dieser Daten erfolgt zu dem Zweck, die Nutzung unserer Website zu ermöglichen (Verbindungsaufbau), 
            die Systemsicherheit und Stabilität dauerhaft zu gewährleisten und die Optimierung unseres Internetangebots zu ermöglichen sowie zu 
            internen statistischen Zwecken. Die IP-Adresse wird nur bei Angriffen auf die Netzinfrastruktur der Webseite sowie zu 
            statistischen Zwecken ausgewertet.
        </Typography.Text>
        <Typography.H4>1.2 Bei der Nutzung der Dienstleistung</Typography.H4>
        <Typography.Text styles={TermsSC.ClearParagraphMargin}>
            Um die Dienstleistung ECATCH zu nutzen, ist die wahrheitsgemässe Eingabe bestimmter 
            Daten zwingend erforderlich. Dies betrifft:
        </Typography.Text>
        <ul>
            <li>Vorname</li>
            <li>Nachname</li>
            <li>Geschlecht</li>
            <li>Geburtsdatum</li>
            <li>E-Mail Adresse</li>
            <li>Alter</li>
            <li>Interessen</li>
        </ul>
        <Typography.Text styles={TermsSC.ClearParagraphMargin}>
            Die Daten benötigen wir zur Abwicklung und Administration unserer Webseite, zur Überprüfung der eingegebenen 
            Daten auf Plausibilität sowie zum Versand der Ware im Falle einer Ersteigerung oder eines Kaufs. Die 
            personenbezogenen Daten können vom Teilnehmer jederzeit eingesehen und geändert, sowie die komplette 
            Löschung des Kontos verlangt werden. Während der Nutzung des Portals durch die unregistrierten Nutzer 
            erheben wir Daten aus statistischen Gründen und um die reibungslose Funktionsfähigkeit des Portals zu 
            ermöglichen. Erhoben werden insbesondere folgende Daten:
        </Typography.Text>
        <ul>
            <li>Abgabe von Geboten</li>
            <li>Frequenz und Dauer des Besuches</li>
            <li>sämtliche Aktivitäten des Nutzers auf der Plattform</li>
        </ul>
        <Typography.H3>2. Nutzung Ihrer Daten zu Werbezwecken</Typography.H3>
        <Typography.H4>2.1. E-Mail-Werbung / Online-Werbung</Typography.H4>
        <Typography.Text>
            Mit Ihrer Teilnahme an einem der Wettbewerbe haben Sie eingewilligt, dass wir Ihre Personendaten 
            für Werbemassnahmen, wie z.B. zur Versendung von E-Mails mit allgemeinen Informationen oder werbendem 
            Charakter (Newsletter), resp. der Auslieferung von kundenspezifischer Werbung bearbeiten. Bei Anmeldung 
            zum Newsletter wird Ihre Email-Adresse zusammen mit Ihrer Einwilligung für Werbezwecke genutzt, bis Sie sich 
            vom Newsletter abmelden. Die Abmeldung ist jederzeit möglich. Wir sind berechtigt, konzernverbundene Unternehmen 
            sowie Dritte mit der technischen Abwicklung von Werbemassnahmen zu beauftragen und sind berechtigt, Ihre Daten 
            zu diesem Zweck weiterzugeben (vgl. unten Ziff. 3). Mit der Auswahl eines Gewinnes im Rahmen des Wettbewerbs 
            willigen Sie dazu ein, dass ihre Daten mit dem Anbieter des Gewinnes geteilt werden. Der Anbieter des Gewinnes 
            kann diese Daten zu eigenen Werbezwecken nutzen. Die Abmeldung ist jederzeit möglich.
        </Typography.Text>
        <Typography.H3>3. Weitergabe der Daten an Dritte</Typography.H3>
        <Typography.Text>
            Wir geben Ihre personenbezogenen Daten nur weiter, wenn Sie ausdrücklich eingewilligt haben, hierfür eine 
            gesetzliche Verpflichtung besteht oder dies zur Durchsetzung unserer Rechte, insbesondere zur Durchsetzung 
            von Ansprüchen aus dem Vertragsverhältnis, erforderlich ist. Darüber hinaus geben wir Ihre Daten an konzernverbundene 
            Unternehmen oder Dritte weiter, soweit dies zur Vertragsabwicklung erforderlich ist. Für diesen Zweck geben wir 
            die erforderlichen Daten an Tochterunternehmen, Transportunternehmen, Banken und andere Dienstleister weiter.
        </Typography.Text>
        <Typography.H3>4. Übermittlung personenbezogener Daten ins Ausland</Typography.H3>
        <Typography.Text>
            Die Betreiberin ist berechtigt, Ihre personenbezogenen Daten auch an konzernverbundene Unternehmen 
            sowie Dritte im Ausland zu übertragen, sofern dies zur Auftragsabwicklung erforderlich ist. 
            Diese sind mindestens im gleichen Umfang wie der Anbieter selbst zum Datenschutz verpflichtet. 
            Wenn das Datenschutzniveau in einem Land nicht dem Schweizerischen entspricht, stellen wir vertraglich 
            sicher, dass der Schutz Ihrer personenbezogenen Daten mindestens demjenigen in der Schweiz entspricht.
        </Typography.Text>
        <Typography.H3>5. Anspruch auf Auskunft, Löschung und Berichtigung</Typography.H3>
        <Typography.Text>
            Sie können jederzeit Auskunft über Ihre von uns gespeicherten personenbezogenen Daten verlangen 
            und verändern. Ein formelles Auskunftsersuchen hat schriftlich und unter Nachweis der Identität 
            zu erfolgen. Sie können jederzeit beantragen Ihre Daten löschen oder berichtigen zu lassen. Dies kann per Post an
            <br />
            Mindnow AG<br />
            Datenschutz<br />
            Okenstrasse 6<br />
            8037 Muttenz<br />
            unter Angabe des Vor- und Nachnamen, der E-Mail Adresse und einer Ausweiskopie geschehen. Wir 
            weisen Sie darauf hin, dass im Falle der Löschung Ihrer Daten eine Inanspruchnahme unserer Dienste 
            nicht oder nicht in vollem Umfang möglich ist. Einwilligungen in bestimmte Datenverarbeitungen können 
            sie jederzeit mit Wirkung für die Zukunft widerrufen. Bitte beachten Sie, dass für bestimmte Daten gesetzliche 
            Aufbewahrungsfristen gelten. Diese Daten müssen bis zum Ablauf der Frist von uns gespeichert werden. Wir 
            sperren diese Dateien in unserem System und nutzen sie ausschliesslich zur Erfüllung der gesetzlichen Pflichten.
        </Typography.Text>
        <Typography.H3>6. Datensicherheit / Schutz gegen Missbrauch</Typography.H3>
        <Typography.Text>
            Wir bedienen uns geeigneter technischer und organisatorischer Sicherheitsmassnahmen, um Ihre bei uns gespeicherten 
            personenbezogenen Daten gegen Manipulation, teilweisen oder vollständigen Verlust und gegen unbefugten Zugriff 
            Dritter zu schützen. Unsere Sicherheitsmassnahmen werden entsprechend der technologischen Entwicklung fortlaufend 
            verbessert. Sie sollten Ihre Zahlungsinformationen stets vertraulich behandeln und das Browserfenster schliessen, 
            wenn Sie die Kommunikation mit uns beendet haben, insbesondere wenn Sie den Computer gemeinsam mit anderen nutzen. 
            Auch den unternehmensinternen Datenschutz nehmen wir sehr ernst. Unsere Mitarbeiter und die von uns beauftragten 
            Dienstleistungsunternehmen sind von uns zur Verschwiegenheit und zur Einhaltung der datenschutzrechtlichen Bestimmungen 
            verpflichtet worden.
        </Typography.Text>
        <Typography.H3>7. Cookies</Typography.H3>
        <Typography.Text>
            Cookies helfen unter vielen Aspekten, Ihren Besuch auf unserer Webseite einfacher, angenehmer und 
            sinnvoller zu gestalten. Cookies sind Informationsdateien, die Ihr Webbrowser automatisch auf der Festplatte 
            Ihres Computers speichert, wenn Sie unsere Internetseite besuchen. Cookies beschädigen weder die Festplatte 
            Ihres Rechners noch werden von diesen Cookies personenbezogene Daten der Anwender an uns übermittelt. Wir setzen 
            Cookies beispielsweise ein, um Sie als wiederkehrenden Benutzer identifizieren zu können. In der Vergangenheit 
            eingegebene Daten werden in Cookies gespeichert, damit Sie diese Daten nicht nochmals eingeben müssen. Die Verwendung 
            führt nicht dazu, dass wir neue personenbezogene Daten über Sie als Onlinebesucher erhalten. Die meisten Internet-Browser 
            akzeptieren Cookies automatisch. Sie können Ihren Browser jedoch so konfigurieren, dass keine Cookies auf Ihrem Computer 
            gespeichert werden oder stets ein Hinweis erscheint, wenn Sie ein neues Cookie erhalten. Die Deaktivierung von Cookies 
            kann dazu führen, dass Sie nicht alle Funktionen unseres Portals nutzen können.
        </Typography.Text>
        <Typography.H3>8. Tracking-Tools</Typography.H3>
        <Typography.Text styles={TermsSC.ClearParagraphMargin}>
            Zum Zwecke der bedarfsgerechten Gestaltung und fortlaufenden Optimierung unserer Seiten nutzen wir den Webanalyse-Dienst 
            Google Analytics. In diesem Zusammenhang werden pseudonymisierte Nutzungsprofile erstellt und kleine Textdateien, 
            die auf Ihrem Computer gespeichert sind („Cookies“), verwendet. Die durch das Cookie erzeugten Informationen über 
            Ihre Benutzung dieser Website wie
        </Typography.Text>
        <ul>
            <li>Browser-Typ/-Version</li>
            <li>Verwendetes Betriebssystem</li>
            <li>Referrer URL (die zuvor besuchte Seite)</li>
            <li>Hostname des zugreifenden Rechners (IP-Adressse)</li>
            <li>Uhrzeit der Serveranfrage</li>
        </ul>
        <Typography.Text>
            werden an Server von Google Inc. in den USA übermittelt und dort gespeichert. Die Informationen werden 
            verwendet, um die Nutzung der Website auszuwerten, um Reports über die Webseitenaktivitäten zusammenzustellen 
            und um weitere mit der Webseitennutzung und der Internetnutzung verbundene Dienstleistungen zu Zwecken der 
            Marktforschung und bedarfsgerechten Gestaltung dieser Internetseiten zu erbringen. Auch werden diese Informationen 
            gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im 
            Auftrag verarbeiten. Gemäss Google Inc. wird in keinem Fall die IP-Adresse mit anderen den Nutzer betreffenden Daten 
            in Verbindung gebracht. Die IP-Adressen werden anonymisiert, so dass eine Zuordnung nicht möglich ist (IP-Masking). 
            Der Nutzer kann die Installation der Cookies durch eine entsprechende Einstellung der Browser-Software verhindern; 
            wir weisen jedoch darauf hin, dass in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich 
            genutzt werden können. Weitere Informationen über den genutzten Webanalyse-Dienst finden Sie auf der Website von Google 
            Analytics. Eine Anleitung, wie sie die Verarbeitung ihrer Daten durch den Webanalyse-Dienst verhindern können, finden 
            Sie unter <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noreferrer">
                https://tools.google.com/dlpage/gaoptout?hl=de.</a>
        </Typography.Text>
{/*         <Typography.H3>9. Data protection/use and disclosure of data</Typography.H3>
        <Typography.Text>
            All information about the data protection is at disposal at the following 
            page <a href="www.cornercard.ch/dataprotection" target="_blank" rel="noreferrer">www.cornercard.ch/dataprotection.</a>
        </Typography.Text> */}
    </Container>
)
