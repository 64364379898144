import React from 'react';
import { FlattenSimpleInterpolation } from 'styled-components';

import { ButtonSC } from './Button.styles';

type GeneralProps = {
    onClick?: () => void;
    styles?: FlattenSimpleInterpolation;
    htmlTypes?: 'button' | 'submit';
    color?: string;
    disabled?: boolean;
};

type BaseButtonProps = React.PropsWithChildren<
    {
        styleTypes?: 'Primary' | 'Outline';
    } & GeneralProps
>;

const BaseButton: React.FC<BaseButtonProps> = ({
    children,
    onClick,
    styles,
    disabled,
    htmlTypes,
    styleTypes = 'Primary',
}) =>
    React.createElement(
        ButtonSC[styleTypes],
        { onClick, styles, disabled, type: htmlTypes },
        <span>{children}</span>
    );

const Primary: React.FC<GeneralProps> = ({ children, ...props }) => (
    <BaseButton {...props}>{children}</BaseButton>
);

const Outline: React.FC<GeneralProps> = ({ children, ...props }) => (
    <BaseButton styleTypes="Outline" {...props}>
        {children}
    </BaseButton>
);

export const Button = {
    Primary,
    Outline,
};
