import { css } from 'styled-components';

import { MediaQueries } from 'shared/config';

const ContainerCSS = css`
    margin-top: 26px;
    max-width: 990px;
    margin-bottom: 122px;
    h3 {
        margin-top: 20px;
        margin-bottom: 15px;
    }
    h4 {
        margin-bottom: 10px;
    }
    h5 {
        margin-bottom: 8px;
    }
    p {
        margin-bottom: 30px;
    }
    ul {
        margin: 7px 0;
        list-style: inside !important;
    }
    li {
        list-style-type: disc;
        margin-left: 20px;
    }
    & * {
        text-align: left !important;
    }
    @media (max-width: ${MediaQueries.tablet}) {
        margin-top: 44px;
    }
    @media (max-width: ${MediaQueries.mobile}) {
        margin-top: 10px;
        margin-bottom: 100px;
    }
`;

const SubTitleCSS = css`
    margin-top: 40px !important;
    margin-bottom: 25px !important;
`;

const ClearParagraphMargin = css`
    margin-bottom: 0 !important;
`;

export const TermsSC = {
    ContainerCSS,
    SubTitleCSS,
    ClearParagraphMargin
};
