import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Router } from '../../router';

import { Footer, Header, MobileMenu } from '../';
import { MobileHamburger } from 'shared/components/ui';

import { useDeviceDetector, useStateToggle } from 'shared/hooks';

import { LPLayout } from './Layout.styles';

export const Layout = () => {
  const { pathname } = useLocation();
  const [showMobileMenu, { toggle, turnOff }] = useStateToggle(false);
  const [isScroll, setIsScroll] = useState(false);

  const { device } = useDeviceDetector();

  const onScroll = () => {
    setIsScroll(window.pageYOffset > 10);
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname])

  return (
    <>
      {device === 'mobile' && (
        <>
          <MobileMenu isOpen={showMobileMenu} closeMenu={turnOff} />
          <MobileHamburger isScroll={isScroll} isOpen={showMobileMenu} onClick={toggle} />
        </>
      )}
      <LPLayout.Wrapper>
        <Header isScroll={isScroll} />
        <Router />
        <Footer />
      </LPLayout.Wrapper>
    </>
  );
};
