import { useCallback, useEffect, useState } from 'react';

import { MediaSizes } from '../config';

type Devices = 'tablet' | 'mobile' | 'desktop';

export const useDeviceDetector = (onFlip?: () => void) => {
    const [detected, setDetected] = useState<boolean>(false);
    const [device, setDevice] = useState<Devices>();
    const [isDashboardUsableOnFlip, setDashboardUsableOnFlip] = useState<boolean>();
    const [isFlipped, setIsFlipped] = useState<boolean>();

    const handleResize = useCallback(() => {
        const windowWidth = window.screen.width;
        const windowHeight = window.innerHeight;

        let size: Devices = 'mobile';

        if (windowWidth >= MediaSizes.mobile) {
            size = windowWidth >= MediaSizes.tablet ? 'desktop' : 'tablet';
        }
        const flippable = windowHeight >= 1024 && windowWidth < MediaSizes.tablet;
        setIsFlipped(isDashboardUsableOnFlip && !flippable);
        setDashboardUsableOnFlip(flippable);
        setDevice(size);
        setDetected(true);
    }, [setIsFlipped, isDashboardUsableOnFlip])

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [handleResize]);

    useEffect(() => {
        if (isFlipped && onFlip) {
            onFlip();
            setIsFlipped(false);
        }
    }, [isFlipped, onFlip]);

	return { detected, device, isDashboardUsableOnFlip };
};
