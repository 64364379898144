import styled, { css } from 'styled-components';

import { Color, MediaQueries } from 'shared/config';

const TextCss = css`
  max-width: 510px;
  margin-top: 23px;

  @media (max-width: ${MediaQueries.mobile}) {
    max-width: 311px;
    margin-top: 14px;
  }
`;

const CardTextCss = css`
  margin-top: 17px;

  @media (max-width: ${MediaQueries.tablet}) {
    text-align: left;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    text-align: center;
  }
`;

const CompanyTitleCss = css`
  font-weight: bold;
  @media (max-width: ${MediaQueries.mobile}) {
    max-width: 300px;
  }
`;

const Root = styled.section`
  margin-top: -216px;
  padding-top: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${MediaQueries.tablet}) {
    margin-top: -211px;
    padding-top: 120px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    padding-top: 100px;
    margin-top: -173px;
  }
`;

const CardList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 160px;
  max-width: 1200px;
  margin-top: 47px;

  @media (max-width: ${MediaQueries.tablet}) {
    margin-top: 57px;
    margin-bottom: 103px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    margin-top: 40px;
    margin-bottom: 56px;
  }
`;

const Card = styled.div`
  display: flex;
  width: 33.3333%;
  padding: 0 15px;

  @media (max-width: ${MediaQueries.tablet}) {
    width: 100%;
    padding: 0;
    margin-bottom: 15px;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    margin-bottom: 18px;
  }
`;

const CardBody = styled.div`
  position: relative;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 20px;
  background-color: ${Color.lp_white};

  &::before {
    position: absolute;
    content: '';
    z-index: -1;
    width: 80%;
    height: 100%;
    top: 40px;
    left: 10%;
    opacity: 0.5;
    border-radius: 20px;
    filter: blur(50px);
    background-color: #ccccd3;
  }

  @media (max-width: ${MediaQueries.tablet}) {
    align-items: flex-start;
    padding: 40px 70px 40px 193px;

    &:before {
      width: 90%;
      left: 5%;
    }
  }

  @media (max-width: ${MediaQueries.mobile}) {
    align-items: center;
    padding: 32px 20px;

    &:before {
      width: 80%;
      left: 10%;
    }
  }
`;

const CardLogo = styled.div`
  width: 64px;
  height: 64px;
  margin-bottom: 40px;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: ${MediaQueries.tablet}) {
    position: absolute;
    left: 64px;
    top: 50%;
    margin-top: -34px;
    margin-bottom: 0;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    width: 48px;
    height: 48px;
    position: initial;
    left: initial;
    top: initial;
    margin-top: 0;
    margin-bottom: 26px;
  }
`;

const LogoList = styled.div`
  display: flex;
  margin-top: 36px;
  width: 100%;
  justify-content: center;

  @media (max-width: ${MediaQueries.mobile}) {
    flex-wrap: wrap;
    margin-top: 0;
  }
`;

const LogoItem = styled.div`
  display: flex;
  width: 21.5%;
  align-items: center;
  justify-content: center;
  height: 80px;

  img {
    max-height: 100%;
    width: auto;
    opacity: .35;
  }

  @media (max-width: ${MediaQueries.tablet}) {
    height: 64px;
    width: 24%;
  }

  @media (max-width: ${MediaQueries.mobile}) {
    height: 58px;
    width: 50%;
    margin-top: 33px;
  }
`;

export const AdvantagesSC = {
  Root,
  CardList,
  Card,
  CardBody,
  CardLogo,
  LogoList,
  LogoItem,

  TextCss,
  CardTextCss,
  CompanyTitleCss
};
