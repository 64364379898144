import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
    Button,
    LanguageSwitcher,
    Icon,
    ColoredIconName,
} from 'shared/components/ui';
import { Navigation } from 'shared/mocks';

import {
    useDeviceDetector,
    useIsLanding,
    usePageScroll,
    useStateToggle,
} from 'shared/hooks';

import { HeaderSC } from './Header.styles';
import { PricingModal } from '..';

type HeaderProps = {
    isScroll: boolean;
};

export const Header: React.FC<HeaderProps> = ({ isScroll }) => {
    const { device } = useDeviceDetector();
    const { t } = useTranslation();
    // const router = useHistory();
    const { scrollTop } = usePageScroll();
    const [isHelpModalOpen, { turnIn: openModal, turnOff: closeModal }] =
        useStateToggle(false);

    // const onHelpClick = () => {
    //     window.open('https://ecatch.tawk.help', '_blank');
    // };

    return (
        <HeaderSC.Root isScroll={isScroll}>
            <PricingModal
                isOpen={isHelpModalOpen}
                closeModalHandler={closeModal}
            />
            <HeaderSC.Header>
                <HeaderSC.LeftPart>
                    <HeaderSC.LogoWrapper>
                        <Icon.Image
                            name={ColoredIconName.logo}
                            onClick={scrollTop.bind(null, false)}
                        />
                    </HeaderSC.LogoWrapper>
                    {device !== 'mobile' && <NavList />}
                </HeaderSC.LeftPart>

                <HeaderSC.RightPart>
                    {device !== 'mobile' && (
                        <>
                            <LanguageSwitcher />
                            {/* <Button.Outline styles={HeaderSC.QuestionMarkBtnCss} onClick={onHelpClick}>
                ?
              </Button.Outline> */}
                        </>
                    )}
                    {/* <Button.Outline onClick={() => router.push('/sign-in')}>
                         {t('lp_header_button_text')}
                    </Button.Outline> */}
                    <Button.Outline onClick={() => openModal()}>
                        {t('lp_pricing_modal_submit_update')}
                    </Button.Outline>
                </HeaderSC.RightPart>
            </HeaderSC.Header>
        </HeaderSC.Root>
    );
};

const NavList = () => {
    const { t } = useTranslation();
    const { scrollToSection } = usePageScroll();
    const { takeMeTo } = useIsLanding();
    const {
        location: { pathname },
    } = useHistory();

    const isLandingPage = pathname === '/';

    return (
        <HeaderSC.Navigation>
            <HeaderSC.List>
                {Navigation.header.map(
                    (link) =>
                        link.section && (
                            <HeaderSC.LinkItem
                                key={link.translation}
                                onClick={() =>
                                    isLandingPage
                                        ? link.section &&
                                          scrollToSection(link.section)
                                        : link.section &&
                                          takeMeTo(
                                              link.section,
                                              scrollToSection
                                          )
                                }
                            >
                                {t(link.translation)}
                            </HeaderSC.LinkItem>
                        )
                )}
            </HeaderSC.List>
        </HeaderSC.Navigation>
    );
};
