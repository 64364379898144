import React, { memo, useRef } from 'react';

import { useParallax } from 'shared/hooks';

import background from '../../../images/feature-illustrations/left-bg.jpg';
import root from '../../../images/feature-illustrations/commerce-root.png';
import notification from '../../../images/feature-illustrations/commerce-notification.png';
import product from '../../../images/feature-illustrations/commerce-product.png';

import backgroundRetina from '../../../images/feature-illustrations/left-bg@retina.jpg';
import rootRetina from '../../../images/feature-illustrations/commerce-root@retina.png';
import notificationRetina from '../../../images/feature-illustrations/commerce-notification@retina.png';
import productRetina from '../../../images/feature-illustrations/commerce-product@retina.png';

import { CommerceSC } from './Commerce.styles';

export const CommerceIllustration = memo(() => {
    const ref = useRef<HTMLDivElement>(null);
    const { value } = useParallax(ref);

    return (
        <CommerceSC.Root ref={ref}>
            <CommerceSC.Background
                srcSet={`${background} 1x, ${backgroundRetina} 2x`}
                src={background}
            />
            <CommerceSC.RootImage
                srcSet={`${root} 1x, ${rootRetina} 2x`}
                src={root}
            />
            <CommerceSC.Notification
                srcSet={`${notification} 1x, ${notificationRetina} 2x`}
                style={{transform: `translateY(${-value / 12}px)`}}
                src={notification}
            />
            <CommerceSC.Product
                srcSet={`${product} 1x, ${productRetina} 2x`}
                style={{transform: `translateY(${-value / 12}px)`}}
                src={product}
            />
        </CommerceSC.Root>
    )
})
