import { css, keyframes } from 'styled-components';

import { MediaQueries } from './mediaQueries';

const HeaderMoveDownKeyframes = keyframes`
    from {
        opacity: 0;
        transform: translateY(-100px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const LogoZoomInKeyframes = keyframes`
    from {
        opacity: 0;
        transform: scale(0);
        transform-origin: top left;
    }
    to {
        opacity: 1;
        transform: scale(1);
        transform-origin: top left;
    }
`;

const ZoomInKeyframes = keyframes`
    from {
        opacity: 0;
        transform: scale(.4);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
`;

const HeroFormFadeUpKeyframes = keyframes`
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const LeftBgFadeInKeyframes = keyframes`
    from {
        opacity: 0;
        transform: translate3d(-167%, -40px, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(-163%, 0, 0);
    }
`;

const RightBgFadeInKeyframes = keyframes`
    from {
        opacity: 0;
        transform: translate3d(80px, -60px, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
`;

const HeroOverviewFadeUpKeyframes = keyframes`
    from {
        opacity: 0;
        transform: translateY(200px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const HeroSupportFadeUpKeyframes = keyframes`
    from {
        opacity: 0;
        top: 45%;
    }
    to {
        opacity: 1;
        top: 29%;
    }
`;

const HeroPreviewFadeUpKeyframes = keyframes`
    from {
        opacity: 0;
        top: 40%;
    }
    to {
        opacity: 1;
        top: 26%;
    }
`;

const FadeDownHeader = css`
    @media (min-width: ${MediaQueries.tablet}) {
        animation: ${HeaderMoveDownKeyframes} 700ms ease-in-out both;
    }
`;

const ZoomInLogo = css`
    @media (min-width: ${MediaQueries.tablet}) {    
        animation: ${LogoZoomInKeyframes} 700ms ease-in-out both;
    }
`;

const ZoomInTitle = css`
    @media (min-width: ${MediaQueries.tablet}) {
        animation: ${ZoomInKeyframes} 700ms ease-in-out both;
    }
`;

const FadeUpHeroForm = css`
    @media (min-width: ${MediaQueries.tablet}) {
        animation: ${HeroFormFadeUpKeyframes} 600ms ease-out both 300ms;
    }
`;

const ZoomInHeroText = css`
    @media (min-width: ${MediaQueries.tablet}) {
        animation: ${ZoomInKeyframes} 600ms ease-out both 400ms;
    }
`;

const FadeInLeftBg = css`
    @media (min-width: ${MediaQueries.tablet}) {
        animation: ${LeftBgFadeInKeyframes} 600ms ease-out both 300ms;
    }
`;

const FadeInRightBg = css`
    @media (min-width: ${MediaQueries.tablet}) {
        animation: ${RightBgFadeInKeyframes} 800ms ease-out both 300ms;
    }
`;

const FadeInHeroOverview = css`
    @media (min-width: ${MediaQueries.tablet}) {
        animation: ${HeroOverviewFadeUpKeyframes} 800ms ease-out both 200ms;
    }
`;

const FadeInHeroSupport = css`
    @media (min-width: ${MediaQueries.tablet}) {
        animation: ${HeroSupportFadeUpKeyframes} 900ms ease-in-out both 200ms;
    }
`;

const FadeInHeroPreview = css`
    @media (min-width: ${MediaQueries.tablet}) {
        animation: ${HeroPreviewFadeUpKeyframes} 900ms ease-in-out both 200ms;
    }
`;

export const AnimationsCS = {
    FadeDownHeader,
    ZoomInLogo,
    ZoomInTitle,
    FadeUpHeroForm,
    ZoomInHeroText,
    FadeInLeftBg,
    FadeInRightBg,
    FadeInHeroOverview,
    FadeInHeroSupport,
    FadeInHeroPreview
}
