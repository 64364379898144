import styled from 'styled-components';

import { Color, MediaQueries } from 'shared/config';

const Line = styled.div`
    width: 20px;
    height: 2px;
    border-radius: 1px;
    position: absolute;
    left: 10px;
    background-color: ${Color.white};
    transform-origin: center;
    transition: all 300ms ease-out;

    &:first-child {
      top: 16px;
    }

    &:last-child {
      width: 16px;
      bottom: 16px;
    }
`;

const Root = styled.div<{ isOpen:boolean, isScroll:boolean }>`
  position: fixed;
  left: 20px;
  top: 53px;
  width: 40px;
  height: 40px;
  background-color: ${Color.black};
  border-radius: 12px;
  z-index: 200;
  transition: top 400ms ease-in-out;
  ${props => props.isOpen && `
    ${Line} {
      width: 15px;
      left: 13px;

      &:first-child {
        top: 19px;
        transform: rotate(45deg);
      }

      &:last-child {
        bottom: 19px;
        transform: rotate(-45deg);
      }
    }
  `}

  ${props => props.isScroll && `
    @media (max-width: ${MediaQueries.mobile}) {
      top: 16px;
    }
  `}
`;

export const MobileHamburgerSC = {
  Root,
  Line
};
